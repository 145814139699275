import { initDataDog } from '@pacvue/frame/datadog'
const { VITE_APP_NODE_ENV } = import.meta.env

// 域名配置及后缀
const hostnameConfig = {
  'product.pacvue.com': 'us',
  'product.pacvue.cn': 'cn',
  'product-eu.pacvue.com': 'eu',
  'producteurope.pacvue.com': 'eu'
}

export default {
  install(app) {
    // 仅生产模式测试datadog生效
    const hostname = window.location.hostname
    if (hostname in hostnameConfig && localStorage.getItem('productline') === 'commerce' && VITE_APP_NODE_ENV === 'production') {
      initDataDog(hostnameConfig[hostname])
    }
  }
}
