
const state = () => ({
  panelPlanListBy3p: {
    sns: [],
    normal: []
  },
  panelPlanListBy1p: {
    ManufacturingFresh: [],
    ManufacturingCore: [],
    ManufacturingBusiness: [],
    SourcingRetail: [],
    SourcingFresh: [],
    SourcingCore: [],
    SourcingBusiness: [],
    sns: [],
    normal: []
  }
})

const actions = {
  setPanelPlanListBy3p() { },
  setPanelPlanListBy1p() { }
}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    watchFields: ['panelPlanListBy3p', 'panelPlanListBy1p']
  }
}
