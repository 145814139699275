import { download1p } from '@!/api/download.js'
import { requestOfCallCancel_1p } from '@!/utils/useCancelRequest'
import { $http1p, RegisterType } from '../http'

// 获取 Card Total 数据
export const getProductSalesTotal = (data) => {
  return $http1p({
    url: 'sales/getProductSalesTotal',
    method: 'post',
    data
  })
}

// 获取 Performance Chart 数据
export const productSalesPerformance = (data, isDetail = false) => {
  return $http1p({
    url: 'sales/productSalesPerformance',
    requestId: isDetail ? 'detail' : 'list',
    method: 'post',
    data
  })
}

// 获取 Sales Table 数据
export const getProductSalesList = (data) => {
  return $http1p({
    url: 'sales/getProductSalesList',
    method: 'post',
    data
  })
}

// 获取 Asin Time  Chart 数据
export const getAsinTimeChart = (data) => {
  return $http1p({
    url: 'sales/getAsinTimeChart',
    method: 'post',
    data
  })
}
// 获取 Asin Time Table 数据
export const getAsinTimeSalesList = (data) => {
  return $http1p({
    url: 'sales/getProductSalesAsinTime',
    method: 'post',
    data
  })
}

// 获取 child Sales 数据
export const getChildSales = (data) => {
  return requestOfCallCancel_1p({
    url: 'sales/getProductSalesChildAsin',
    isUnique: true,
    method: 'post',
    data
  })
}

// 获取 child tag 数据
export const getProductSalesChildTag = (data) => {
  return requestOfCallCancel_1p({
    url: 'sales/getProductSalesChildTag',
    method: 'post',
    isUnique: true,
    data
  })
}

// 获取 brand category child tag 数据
export const getProductSalesChildBrandCategory = (data) => {
  return requestOfCallCancel_1p({
    url: 'sales/getProductSalesChildBrandCategory',
    method: 'post',
    isUnique: true,
    data
  })
}

// 查询是否有SNS权限
export const hasSnsApi = () => {
  return $http1p({
    url: 'sales/hasSnsData',
    method: 'post'
  })
}

// 下载target模版
export function downloadSalesTarget(data) {
  return download1p(
    '/sales/downloadSalesTarget',
    data,
    '',
    { requestRegisterType: RegisterType.GLOBAL }
  )
}

// 上传模版
export const uploadSalesTarget = (data) => {
  return $http1p({
    url: 'sales/uploadSalesTarget',
    method: 'post',
    data
  })
}

// 查询分页excel
export const pageSalesTargetExcelRecord = (data) => {
  return $http1p({
    url: 'sales/pageSalesTargetExcelRecord',
    method: 'post',
    data
  })
}

// 获取日历延迟数据日期
export const getDelayDateOfReport = (data) => {
  return $http1p({
    url: 'sales/getDelayDateOfReport',
    method: 'post',
    data
  })
}
