export const WatchFields = [
  'commerceType',
  // 'channelList',
  'marketStoreValueMap',
  'brandCategoryValueMap',
  'originBrandCategoryValueMap',
  'customBrandCategoryValueMap',
  'customBrandStore',
  'customCategoryStore',
  'overviewJumpHistoryMap',
  'distributorAndSellingMap',
  'tagOwnerValueMap',
  'tagValueMap',
  'repSuggestionType',
  'salesHourlyType',
  'problemTypeValueMap',
  'salePanelMap',
  'realTimeSalePanelMap',
  'vendorCodeValue',
  'downloadTaskIdList'
]
