export default {
  AE: 'Emirati Dirham',
  AU: 'Australian Dollar',
  BR: 'Brazil Real',
  CA: 'Canadian Dollar',
  DE: 'Euro',
  EG: 'Egyptian Pound',
  IN: 'Indian Rupee',
  JP: 'Japanese Yen',
  MX: 'Mexican Peso',
  PL: 'Poland zlotych',
  SA: 'Saudi Riyal',
  SE: 'Swedish krona',
  SG: 'Singapore Dollar',
  TR: 'Turkey Liras',
  UK: 'British Pound',
  US: 'US Dollar'
}
