import { UseService, $request as request } from '@commerceCommon/utils/http.js'
import { useCommonStore } from '@!/store/index.js'
import { computed } from 'vue'
export { RegisterType, DownloadConfig, UseSameResult } from '@commerceCommon/utils/http.js'

const { VITE_APP_DATA_1P, VITE_APP_DATA_3P, VITE_APP_SHARETAGURL, VITE_APP_COUNTRY, VITE_APP_SOVURL, VITE_APP_MENU_API, VITE_APP_COMMERCE_COMMON } = import.meta.env

const UseService1P = UseService(VITE_APP_DATA_1P)
export const $http1p = request.use(UseService1P)

const UseService3P = UseService(VITE_APP_DATA_3P)
export const $http3p = request.use(UseService3P)

const UseServiceTag = UseService(VITE_APP_SHARETAGURL, { headers: { productline: `commerce${VITE_APP_COUNTRY}` }})
export const $httpTag = request.use(UseServiceTag)

const UseServiceSov = UseService(VITE_APP_SOVURL)
export const $httpSov = request.use(UseServiceSov)

const UseServiceMenu = UseService(VITE_APP_MENU_API)
export const $httpMenu = request.use(UseServiceMenu)

const UseServiceCommon = UseService(VITE_APP_COMMERCE_COMMON)
export const $httpCommon = request.use(UseServiceCommon)

export const $request = request

const currentCommerceType = computed(() => {
  return useCommonStore().commerceType
})

request.interceptors.request.use(
  function(config) {
    if ([VITE_APP_COMMERCE_COMMON].includes(config.baseURL) && !config.headers.platform) {
      config.headers.platform = currentCommerceType.value.toUpperCase()
    }
    // header新增webfrom属性，给admin平台统计，不准，未考虑dispute claim等独有页面情况
    if (!config.headers.webfrom) {
      config.headers.webfrom = currentCommerceType.value === 'Vendor' ? '1' : '3'
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)
