import { $http1p, $http3p } from '@!/api/http'
import { dayjs } from '@pacvue/element-plus'

const snsClientDate = dayjs().format('YYYY-MM-DD')

/**
 *
 * @param data
 * @param isVendor true 为 Vendor false 为 Seller 默认为 true
 * @returns {*|Promise<Awaited<unknown>[]>}
 */
export const getSnsTop10Asin = (data, isVendor = true) => {
  return isVendor ? $http1p({
    url: 'snsOverview/getSnsTop10Asin',
    method: 'post',
    data: { ...data, snsClientDate }
  }) : $http3p({
    url: 'snsOverview/getSnsTop10Asin',
    method: 'post',
    data: { ...data, snsClientDate }
  })
}

export const hasSnsApi3p = () => {
  return $http3p({
    url: 'sellerSales/hasSnsData',
    method: 'post'
  })
}

