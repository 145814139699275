import { requestOfCallCancel } from '@!/utils/useCancelRequest'
import { $http3p } from '../http.js'
const { VITE_APP_USERURL } = import.meta.env

export const GetUserInfoApi = () => {
  return $http3p({
    url: VITE_APP_USERURL + 'User/userinfo',
    method: 'get',
    isIgnoreRequestRegister: true
  })
}

export const getAccountManageAllUser_3p = () => {
  return requestOfCallCancel({
    url: 'accountManage/allUser',
    method: 'get',
    _autoCancel: false
  })
}

export const GetUserPermissionApi = () => {
  return $http3p({
    url: 'accountManage/getUserPermission',
    method: 'post',
    isIgnoreRequestRegister: true
  })
}
