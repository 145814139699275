// sales viewType

export const TimeDimensionEnum = {
  'P': '2',
  'T': '1'
} as const

// sales TimeDimension.Today -- setting

export const TDByTodaySettingEnum = {
  'Cumulative': 'cumulative',
  'Hourly': 'hourly'
} as const

export const TDByTodaySettingPropEnum = {
  'Cumulative': 'totalDataList',
  'Hourly': 'dataList'
} as const

// Top Ten Shakers sortType
export const TopTenShakersSortTypeEnum = {
  'Top': 'T',
  'Bottom': 'B'
} as const

// Top Ten Shakers chainType
export const TopTenShakersChainTypeEnum = {
  'PoP': 'P',
  'YoY': 'Y'
} as const

export const TabConstant = {
  ManufacturingFresh: [
    {
      key: 'ST',
      show: 1,
      checked: true,
      title: 'Ordered Performance',
      component: 'OrderedPerformance'
    },
    {
      key: 'SHP',
      show: 1,
      checked: true,
      title: 'Shipped Performance',
      component: 'ShippedPerformance'
    },
    { key: 'TOP', show: 1, checked: true, title: 'Top 10', component: 'TopTen' }
  ],
  ManufacturingCore: [
    {
      key: 'ST',
      show: 1,
      checked: true,
      title: 'Ordered Performance',
      component: 'OrderedPerformance'
    },
    {
      key: 'SHP',
      show: 1,
      checked: true,
      title: 'Shipped Performance',
      component: 'ShippedPerformance'
    },
    { key: 'TOP', show: 1, checked: true, title: 'Top 10', component: 'TopTen' }
  ],
  ManufacturingBusiness: [
    {
      key: 'ST',
      show: 1,
      checked: true,
      title: 'Ordered Performance',
      component: 'OrderedPerformance'
    },
    {
      key: 'SHP',
      show: 1,
      checked: true,
      title: 'Shipped Performance',
      component: 'ShippedPerformance'
    },
    { key: 'TOP', show: 1, checked: true, title: 'Top 10', component: 'TopTen' },
    {
      key: 'CR',
      show: 1,
      checked: true,
      title: 'Customer Returns',
      component: 'CustomerReturns'
    }
  ],
  SourcingRetail: [
    {
      key: "BO",
      show: 1,
      checked: true,
      title: "Buybox Ownership Trend",
      component: "BuyboxOwnership"
    },
    {
      key: 'SHP',
      show: 1,
      checked: true,
      title: 'Shipped Performance',
      component: 'ShippedPerformance'
    },
    { key: 'TOP', show: 1, checked: true, title: 'Top 10', component: 'TopTen' },
    {
      key: 'CR',
      show: 1,
      checked: true,
      title: 'Customer Returns',
      component: 'CustomerReturns'
    }
  ],
  SourcingFresh: [
    {
      key: 'SHP',
      show: 1,
      checked: true,
      title: 'Shipped Performance',
      component: 'ShippedPerformance'
    },
    { key: 'TOP', show: 1, checked: true, title: 'Top 10', component: 'TopTen' }
  ],
  SourcingCore: [
    {
      key: 'SHP',
      show: 1,
      checked: true,
      title: 'Shipped Performance',
      component: 'ShippedPerformance'
    },
    { key: 'TOP', show: 1, checked: true, title: 'Top 10', component: 'TopTen' }
  ],
  SourcingBusiness: [
    {
      key: 'SHP',
      show: 1,
      checked: true,
      title: 'Shipped Performance',
      component: 'ShippedPerformance'
    },
    { key: 'TOP', show: 1, checked: true, title: 'Top 10', component: 'TopTen' },
    {
      key: 'CR',
      show: 1,
      checked: true,
      title: 'Customer Returns',
      component: 'CustomerReturns'
    }
  ]
} as const
/**
 * Home AdSalesPerformance图表Metrics，1P&3P共用，按需添加属性
 */
export const AdSalesPerformanceMetrics = (isVendor = false) => ({
  'Ad Sales': {
    label: $t('common.adSales'),
    formatType: 'money',
    columnKey: 'hasAdSales',
    sqlName: 'productAdSales'
  },
  'Organic Sales': {
    label: $t('home.common.organicSales'),
    formatType: 'money',
    columnKey: 'hasOrganicSales',
    sqlName: 'organicSales'
  },
  'Ad Clicks': {
    label: $t('common.adClicks'),
    formatType: 'number',
    columnKey: 'hasAdClicks',
    sqlName: 'adClickList'
  },
  'Ad Spend': {
    label: $t('common.adSpend'),
    formatType: 'money',
    columnKey: 'hasAdSpend',
    sqlName: 'adSpendList'
  },
  'ROAS': {
    label: $t('common.roas'),
    formatType: 'money',
    columnKey: 'hasROAS',
    sqlName: 'roasList'
  },
  'ACOS': {
    label: $t('common.acos'),
    formatType: 'percent',
    columnKey: 'hasACOS',
    sqlName: 'acosList',
    sellerTip: $t('common.acosTipSeller')
  },
  ...isVendor ? {
    'T-ACOS': {
      label: $t('common.TACOS'),
      formatType: 'percent',
      columnKey: 'hasTACOS',
      sqlName: 'tAcosList',
      vendorTip: $t('common.totalAcosTipVendor')
    }
  } : {},
  'CPC': {
    label: $t('common.cpc'),
    formatType: 'money',
    columnKey: 'hasCPC',
    sqlName: 'cpcList'
  },
  'Ad CVR': {
    label: $t('common.adCvr'),
    formatType: 'percent',
    columnKey: 'hasAdCVR',
    sqlName: 'cvrList'
  },
  'Ad CPA': {
    label: $t('home.common.adCpa'),
    formatType: 'money',
    columnKey: 'hasAdCPA',
    sqlName: 'cpaList'
  },
  'Ad CTR': {
    label: $t('home.common.adCtr'),
    formatType: 'percent',
    columnKey: 'hasAdCTR',
    sqlName: 'ctrList'
  },
  'Ad Sales %': {
    label: $t('common.adSalesPercent'),
    formatType: 'percent',
    columnKey: 'hasAdSalesPercentage',
    sqlName: 'adSalesPercentList',
    vendorTip: $t('home.common.adSalesVendorTip'),
    sellerTip: $t('home.common.adSalesSellerTip')
  },
  'Organic Sales %': {
    label: $t('home.common.organicSalesPercent'),
    formatType: 'percent',
    columnKey: 'hasOrganicSalesPercentage',
    sqlName: 'organicSalesPercentList',
    vendorTip: $t('home.common.organicSalesTipVendor'),
    sellerTip: $t('home.common.organicSalesTipSeller')
  }
})
