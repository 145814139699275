import $eventBus from '@!/utils/eventBus.js'
import { isEmpty } from '@commerceCommon/utils/common'
import { pacvueToken } from '@pacvue/utils'
import { PacvueNotify } from '@pacvue/element-plus'
import { useCommonStore } from '@!/store'
const { VITE_APP_SOCKET_3P } = import.meta.env
const clientTimeOut = 5000
const serverTimeOut = 5000
let clientTimer = null
let serverTimer = null
/**
 * 页面事件
 */
const PageEventEnum = {
  claim: 'claimMsg',
  pim: 'importMsg',
  amazon_tag_sync: 'syncTagMsg'
}

export function initWebSocket() {
  const token = pacvueToken.getTokens()
  if (window.commerceWebSocket || !VITE_APP_SOCKET_3P || !token) {
    return
  }
  const webSocket = new WebSocket(`${VITE_APP_SOCKET_3P}page`, [token])
  webSocket.onerror = webSocketOnError
  webSocket.onmessage = webSocketOnMessage
  webSocket.onclose = closeWebSocket
  webSocket.onopen = onOpenSocket
  window.commerceWebSocket = webSocket
  return webSocket
}

function handleReconnect() {
  setTimeout(() => {
    initWebSocket()
  }, 15000)
}

function webSocketOnError(e) {
  window.commerceWebSocket.close()
}

function webSocketOnMessage(msg) {
  const { pageType, message, type } = JSON.parse(msg.data)
  if (type === 'pong') {
    heartbeatCheck()
  } else {
    if (!isEmpty(PageEventEnum[pageType])) {
      $eventBus.emit(PageEventEnum[pageType], {
        msg: message, type
      })
    }
  }
}

// 关闭websocket
function closeWebSocket(e) {
  console.log('WebSocket Closed...', e)
  window.commerceWebSocket = null
  clearTimeout(clientTimer)
  clearTimeout(serverTimer)
  if (e.code !== 1000 || e.reason !== 'client close') {
    // 除主动close以外所有异常close都触发重连
    handleReconnect()
  }
  $eventBus.off('claimMsg')
  $eventBus.off('syncTagMsg')
}

function onOpenSocket() {
  console.log('Open WebSocket...')
  $eventBus.emit('openSuccess')
  // 处理Claim全局通信信息
  $eventBus.on('claimMsg', claimMsgEventHandle)
  // tag从广告同步完成的通知
  $eventBus.on('syncTagMsg', syncTagMsgEventHandle)
  heartbeatCheck()
}

function heartbeatCheck() {
  clearTimeout(clientTimer)
  clearTimeout(serverTimer)
  clientTimer = setTimeout(() => {
    window.commerceWebSocket.send(JSON.stringify({ pageType: 'pim', type: 'ping' }))
    serverTimer = setTimeout(() => {
      handleReconnect()
    }, serverTimeOut)
  }, clientTimeOut)
}

const claimMsgEventHandle = ({ msg, type }) => {
  let notifyMsg = ''
  if (type === 'case_in_review') {
    notifyMsg = $t('claimDetail.fnskuReimbursementReviewTip.tip1', [msg])
  }
  if (type === 'case_diff_fnsku') {
    const { caseId, originalFnsku, replyFnsku } = msg
    notifyMsg = $t('claimDetail.fnskuReimbursementReviewTip.tip2', [caseId, replyFnsku, originalFnsku])
  }
  PacvueNotify({
    title: $t('common.confirmTitle'),
    dangerouslyUseHTMLString: true,
    message: notifyMsg,
    pacType: 'warning',
    duration: 0,
    customClass: 'claim-notification'
  })
}

const syncTagMsgEventHandle = ({ msg }) => {
  const { platform, syncStatus, syncedCount } = msg
  if (syncedCount > 0 && syncStatus) {
    const commonStore = useCommonStore()
    const commerceType = platform === 1 ? 'Vendor' : 'Seller'
    commonStore.setProductTagStore([], commerceType)
  }
  $eventBus.emit('catalogTagSync', { msg })
  PacvueNotify({
    title: $t('common.confirmTitle'),
    message: $t('common.synchronizationIsComplete'),
    pacType: syncStatus ? 'success' : 'error'
  })
}
