import { PacvueIconSideDisplay, PacvueIconOpenNewTab } from '@pacvue/svg-icon'

// 打开方式 枚举
export const OpenAsinDetailTypeEnum = {
  Drawer: 'Drawer',
  Tab: 'Tab'
} as const

const OpenAsinDetailTypeLabelEnum = {
  get Drawer() {
    return $t('asinDetailBaseInfo.OpenAsinDetail.LabelEnum.Drawer')
  },
  get Tab() {
    return $t('asinDetailBaseInfo.OpenAsinDetail.LabelEnum.Tab')
  }
} as const

// 打开方式 map
export const OpenAsinDetailTypeEnumArr = [
  OpenAsinDetailTypeEnum.Drawer,
  OpenAsinDetailTypeEnum.Tab
] as const

// 打开方式 Tip 枚举
const OpenAsinDetailTypeTipEnum = {
  get Drawer() {
    return $t('asinDetailBaseInfo.OpenAsinDetail.TipEnum.Drawer')
  },
  get Tab() {
    return $t('asinDetailBaseInfo.OpenAsinDetail.TipEnum.Tab')
  }
} as const

// 打开方式 Icon 枚举
const OpenAsinDetailTypeIconEnum = {
  Drawer: PacvueIconSideDisplay,
  Tab: PacvueIconOpenNewTab
} as const

// 打开方式列表集合
export const OpenAsinDetailTypeList = [
  {
    iconRender: OpenAsinDetailTypeIconEnum.Drawer,
    value: OpenAsinDetailTypeEnum.Drawer,
    get label() {
      return OpenAsinDetailTypeLabelEnum.Drawer
    },
    get tip() {
      return OpenAsinDetailTypeTipEnum.Drawer
    }
  }, {
    iconRender: OpenAsinDetailTypeIconEnum.Tab,
    value: OpenAsinDetailTypeEnum.Tab,
    get label() {
      return OpenAsinDetailTypeLabelEnum.Tab
    },
    get tip() {
      return OpenAsinDetailTypeTipEnum.Tab
    }
  }
] as const

// 新手导航提示
export const OpenAsinDetailTypeDriverTipEnum = {
  get  Drawer() {
    return $t('asinDetailBaseInfo.OpenAsinDetail.DriverTipEnum.Drawer')
  },
  get Tab() {
    return $t('asinDetailBaseInfo.OpenAsinDetail.DriverTipEnum.Tab')
  }
} as const
