export const ANALYTICS_PAGELIST = [
  'View Login Screen',
  'Login Fail',
  'Login Success',
  'Logout Success',
  'Switch To Other Platforms Success',
  'Switch To Other Platforms Fail',
  'Authorize sp-api',
  'Authorize ads profile',
  'Home',
  'Brand Sales Overview',
  'Category Sales Overview',
  'Inventory Overview',
  'Profitability Overview',
  'Alert Overview',
  'Buybox Overview',
  'Merchandising Overview',
  'Reviews Overview',
  'Dispute Overview',
  'Alerts',
  'Alert History',
  'Dispute',
  'Claim',
  'Claim History',
  'Brand Audit',
  'Product Audit',
  'Reviews',
  'Buybox Tracker',
  'Repricer',
  'Real Time Sales',
  'Sales',
  'Inventory',
  'FBM Inventory',
  'Inventory Ledger',
  'Return',
  'Profitability',
  'Consumption Forecast',
  'Order',
  'Coupon',
  'Promotion',
  'Competitor Tracker',
  'Keyword Analysis',
  'Share of Voice',
  'Catalog',
  'Default Report',
  'Search default report',
  'Download default report',
  'My Report',
  'My report history',
  'Periscope Report',
  'MailBox',
  'Automatic Rule',
  'Template Management',
  'Download Center',
  'Pacvue Tools-Data Sync Plugin',
  'My account',
  'User Management',
  'Settings',
  'Custom Forecast Setting',
  'Catalog Variation Testing',
  'Deduction',
  'Dispute',
  'Payment',
  'Alert History',
  'SnS Overview',
  'A+ Content',
  'Add A+ Content',
  'Periscope',
  'BuyboxTracker Detail',
  'NielsenIQ',
  'Product Audit Detail',
  'Catalog Upload Queue',
  'Claim Overview',
  'PIM',
  'PO Forecast'
]

export const ANALYTICS_PAGE_MAPPER = {
  BrandSalesOverview: 'Brand Sales Overview',
  CategorySalesOverview: 'Category Sales Overview',
  InventoryOverview: 'Inventory Overview',
  ProfitabilityOverview: 'Profitability Overview',
  AlertOverview: 'Alert Overview',
  BuyboxOverview: 'Buybox Overview',
  MerchandisingOverview: 'Merchandising Overview',
  OptimizationOverview: 'Reviews Overview',
  DisputeOverview: 'Dispute Overview',
  ClaimOverview: 'Claim Overview',
  AlertHistory: 'Alert History',
  BrandAudit: 'Brand Audit',
  ProductAudit: 'Product Audit',
  BuyboxTracker: 'Buybox Tracker',
  ConsumptionForecast: 'Consumption Forecast',
  CompetitorTracker: 'Competitor Tracker',
  KeywordAnalysis: 'Keyword Analysis',
  ShareOfVoice: 'Share of Voice',
  CatalogMain: 'Catalog',
  DefaultReport: 'Default Report',
  MyReport: 'My Report',
  AutomaticRule: 'Automatic Rule',
  TemplateManagement: 'Template Management',
  DownloadCenter: 'Download Center',
  PacvueTools: 'Pacvue Tools-Data Sync Plugin',
  MyAccount: 'My account',
  UserManage: 'User Management',
  ReportHistory: 'My report history',
  Setting: 'Settings',
  VariationTesting: 'Catalog Variation Testing',
  ClaimHistory: 'Claim History',
  SNSOverview: 'SnS Overview',
  APlusContent: 'A+ Content',
  addAPlus: 'Add A+ Content',
  BuyboxTrackerDetail: 'BuyboxTracker Detail',
  MarketShare: 'NielsenIQ',
  ProductAuditDetail: 'Product Audit Detail',
  UploadQueue: 'Catalog Upload Queue'
}

export const IGNORE_ANALYTICS_PAGELIST = [
  'AlertsMain',
  'Reviews',
  'Real Time Sales',
  'Sales',
  'Return',
  'Inventory',
  'Profitability',
  'Coupon',
  'Promotion',
  'Keyword Analysis',
  'Share of Voice',
  'login',
  'Periscope',
  'Alert History',
  'NielsenIQ',
  'Alerts',
  'SnS Overview',
  'Deduction'
]

export const hostRegionMapper = {
  'product.pacvue.com': 'COM',
  'product.pacvue.cn': 'CN',
  'product-eu.pacvue.com': 'EU',
  'producteurope.pacvue.com': 'EU'
}
