import CurrencySymbol from '@commerceCommon/const/CurrencySymbol'
import { getSettings, getCommerceSettings } from '@!/api/Account/settings'
import { GetAdSpApiNotInCommerceCountApi } from '@!/api/Account/Authorization.js'
import { GetUserInfoApi } from '@!/api/Account/user.js'
import { app, InitApp } from '@pacvue/frame'
import { initwebSocket } from '@pacvue/frame/websocket'
import { getIsTopTen } from './util'

const state = () => {
  return {
    user: null,
    userSettings: null,
    sharePage: true,
    spApiNotInCommerceCount: 0
  }
}

const getters = {
  isDemo(state) {
    return [432, 3438].includes(state.user?.clientId) || state.user?.userName?.toLowerCase() === 'Demo@pacvue.com'.toLowerCase() ||
    state.user?.userName?.toLowerCase() === 'Pacvue Demo'.toLowerCase()
  },
  isTenLevel(state) {
    return getIsTopTen(state.user?.clientId)
  },
  isGroupM(state) {
    return [62, 515, 210454].includes(state.user?.clientId)
  },
  hasSalesImpactAnalysis(state) {
    return [62, 515, 210454, 2523].includes(state.user?.clientId)
  },
  isNewVersionGroupM(state) {
    return [62, 515].includes(state.user?.clientId)
  },
  defaultCountryCode(state) {
    return state.userSettings?.defaultCurrency || 'US'
  },
  defaultMoneySymbol(state) {
    return CurrencySymbol[state.defaultCountryCode] || '$'
  },
  isTestAdminUser(state) {
    return state.user?.clientId === 62
  },
  // 是否展示Content Authority
  isShowContentAuthority(state) {
    return !!state.userSettings?.featureList?.includes('ContentAuthority')
  },
  locale(state) {
    const userSettings = state.userSettings
    if (userSettings) {
      return userSettings.language || 'EN'
    }
    if (window.location.href.includes('.cn')) {
      return 'ZH'
    }
    return 'EN'
  }
}

function firstGetUserInfo(data) {
  if (localStorage.getItem('SignFlag') === 'true') {
    app.config.globalProperties.$eventHub.emit('getUserInfo', data)
  }
}

const actions = {
  logout() {
    if (window.commerceWebSocket) {
      window.commerceWebSocket.close(1000, 'client close')
    }
    if (this.user) {
      this.user.userName = null
    }
  },
  userAction() {
    return new Promise((resolve, reject) => {
      GetUserInfoApi()
        .then((useInfo) => {
          this.SET_USER(useInfo)
          sessionStorage.setItem('useInfo', JSON.stringify(useInfo))
          localStorage.setItem('uid', useInfo.userId)
          window.socketErrCount = 0
          firstGetUserInfo(useInfo)
          resolve(useInfo)
          initwebSocket()
        })
        .catch(() => {
          reject(false)
        })
    })
  },
  async userSetting(route) {
    if (route) {
      const { to, from } = route
      if (InitApp.settingInterceptors && Object.keys(InitApp.settingInterceptors).length > 0) {
        let phase = 0
        let currPhaseInterceptors = []
        while ((InitApp.settingInterceptors[`phase${phase++}`] || []).length > 0) {
          currPhaseInterceptors = InitApp.settingInterceptors[`phase${phase - 1}`] || []
          const promises = currPhaseInterceptors.map((itc) => Promise.resolve(itc(this, to, from)))
          await Promise.all(promises)
        }
      }
    }
    return Promise.all([
      getSettings(),
      getCommerceSettings()
    ]).then(([setting, commerceSettings]) => {
      setting.asinStatus = !!setting.asinStatusSwitch
      setting.commerceSettings = commerceSettings
      setting.language = localStorage.getItem('locale') || 'EN'
      this.SET_USERSETTINGS(setting)
      const el = document.getElementById('app')
      if (setting.themeColour === 'white') {
        document.getElementsByTagName('body')[0].setAttribute('class', 'theme1')
      } else if (setting.themeColour === 'black') {
        document.getElementsByTagName('body')[0].setAttribute('class', 'theme2 borderversion')
      } else {
        document.getElementsByTagName('body')[0].setAttribute('class', 'theme2')
      }
    })
  },
  SET_USER(user) {
    this.user = user
  },
  SET_USERSETTINGS(setting) {
    // 组件日期格式化是从session种获取的
    setting.dateFormat = '0'
    this.userSettings = setting
    window.perSetting = setting
    sessionStorage.setItem('setting', JSON.stringify(setting))
    if (setting.defaultCurrency) {
      this.user.defaultCurrency = setting.defaultCurrency
    }
    sessionStorage.setItem('setting', JSON.stringify(setting))
  },
  SET_USERCURRENCY(currency) {
    this.userSettings.defaultCurrency = currency
    this.user.defaultCurrency = currency
    sessionStorage.setItem('setting', JSON.stringify(this.userSettings))
  },
  setSpApiNotInCommerceCount(count) {
    this.spApiNotInCommerceCount = count
  },
  getSpApiNotInCommerceCount() {
    return new Promise((resolve) => {
      GetAdSpApiNotInCommerceCountApi()
        .then((data) => {
          this.setSpApiNotInCommerceCount(data)
          resolve(data)
        })
        .catch(() => {
          resolve(0)
        })
    })
  }
}

export default {
  state,
  getters,
  actions
}
