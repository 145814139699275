import Main from '@pacvue/frame/layout/Main.vue'
export default [
  {
    name: 'sortDetail',
    path: '/chaptersDetail',
    component: Main,
    meta: {},
    sort: 0,
    children: [
      // 匹配所有detail
      {
        name: 'AsinDetail',
        path: '/:chapters+/detail/asin',
        component: () => import('@!/views/Detail/AsinDetail/index.vue'),
        meta: {
          isShowTime: false,
          showCompareCheck: false,
          autoTestPrefix: 'auto_bm174'
        }
      }
    ]
  }
]
