import { isEmpty } from '@commerceCommon/utils/common.js'
const { VITE_APP_NODE_ENV } = import.meta.env

/**
 * modules中按模块或组件创建多语言配置文件
 * 各模块中翻译的内容按英文字母顺序排序，方便查找
 */
export default {
  // 将commerce-v3-web 代码中的key与 localise 仓库中的 key 对应
  install({ __VUE_I18N_SYMBOL__, _context: { provides }}) {
    const { global: { t, getLocaleMessage }} = provides[__VUE_I18N_SYMBOL__]
    let messages = getLocaleMessage('pacvue-i18n')
    let isMessageEmpty = isEmpty(messages)
    const isProduction = VITE_APP_NODE_ENV === 'production'

    /* 说明：
      由于localise仓库把Commerce所有的key放在了一个文件里
      所以为了区分CommerceAmazon和CommerceCommon以及CommerceWalmart的key
      从引用里拦截并篡改了`i18n.global.t`的方法
    */
    /* 实际效果：
      $t('common.seller') => $t('CommerceAmazon.common.seller')
      $t('CommerceCommon.DownloadButton.Download') => $t('CommerceCommon.DownloadButton.Download')
      $t('key12345') => $t('key12345')
    */
    const newT = function(v, x) {
      // 先从CommerceAmazon中查找，再从CommerceCommon和全局(例如Settings页面用了原先广告的翻译文件)中查找中查找
      // return t(`CommerceAmazon.${v}`, x) === `CommerceAmazon.${v}`
      //   ? t(v, x)
      //   : t(`CommerceAmazon.${v}`, x)
      // 中台国际化内容会有空的情况
      if (!v) {
        return ''
      }
      if (isMessageEmpty) {
        messages = getLocaleMessage('pacvue-i18n')
        isMessageEmpty = isEmpty(messages)
      }
      if (!messages[v] && !messages[`CommerceAmazon.${v}`] && !isProduction) {
        console.error('Missing key: ', v)
      }
      if (messages[v]) {
        return messages[v].startsWith('@:')
          ? t(messages[v].slice(2), x)
          : t(v, x)
      } else if (messages[`CommerceAmazon.${v}`]) {
        let i18nValue = messages[`CommerceAmazon.${v}`]
        if (i18nValue.includes('@:')) {
          if (i18nValue.includes(' ') || i18nValue.includes('{')) {
            i18nValue = i18nValue.replace(/@:([^\s{]+)/g, (_, key) => {
              return t(key)
            })
            i18nValue = i18nValue.replace(/{(\w+)}/g, (match, key) => {
              return x[key] !== undefined ? x[key] : match
            })
            return i18nValue
          } else {
            return t(i18nValue.slice(2), x)
          }
        } else {
          return t(`CommerceAmazon.${v}`, x)
        }
      } else {
        return t(v, x)
      }
    }
    provides[__VUE_I18N_SYMBOL__].global.t = newT.bind(this)
    // 调试时开启下面两行
    // localStorage.setItem('locale', 'ZH')
    // i18n.global.locale = 'ZH'
  }
}
