import { routerProxyByCancelRequest } from '@!/utils/useCancelRequest'
import { getFilterRoutesOfType } from '@commerceCommon/utils/router'
import routerRebuild from '@pacvue/frame/routerRebuild'

/**
 *
 * modules 里面统一添加sort字段
 * 例如 Home sort = 100 以后想牌在Home 就用 50 向往后排 就用 150
 *
 */
// 导入 modules 下的全部路由 eager: true 代表同步
const modules = import.meta.glob('./modules/**.**', { eager: true })
const Routes = getFilterRoutesOfType(Object.values(modules).map(item => item.default).sort((a, b) => a.sort - b.sort))

const router = routerRebuild(Routes, true, 'commerce')

routerProxyByCancelRequest(router)

export default router
