import { get, set, upperFirst, attempt, cloneDeep } from 'lodash-es'
import { watch } from 'vue'
import { useRouterStore } from '@#/store/index'
import { trimAll, toType } from '@commerceCommon/utils/ts-common'
import { useRoute } from 'vue-router'

/**
 * @description: 创建一个use 函数 返回 当前路由store
 * @param {object<Module>} storeModuleMap
 * @return {object}
 */
export const createUseRouterStore = ({ storeModuleMap }) => {
  const useRouterStore = (routeStoreModuleName = '') => {
    const nextStoreModuleName = attempt(() => {
      if (routeStoreModuleName) {
        return routeStoreModuleName
      }
      const route = useRoute()
      const curRouteStoreName = route.meta?.storeName || route.name
      if (!curRouteStoreName) {
        console.error(`useStoreForm Error: ${curRouteStoreName} is not defined`)
        return
      }
      return curRouteStoreName
    })
    if (!nextStoreModuleName) {
      return
    }
    const useStoreName = 'use' + upperFirst(nextStoreModuleName) + 'Store'
    return storeModuleMap[useStoreName]()
  }
  return useRouterStore
}

// 获取格式化写入路径
const getFormatModelPath = (modelPath = '') => {
  const trimModelPatch = trimAll(modelPath)
  return trimModelPatch.replace(
    /(\[)|(\])/gm,
    (match, p1, p2) => {
      if (p1) {
        return '.'
      } else if (p2) {
        return ''
      } else {
        return match
      }
    }
  )
}

const useStoreFormInit = (useProps = {}) => {
  const { getStorePath, curRouterStore, setForm, isInit = true } = useProps
  const getStoreValue = (state = curRouterStore) => get(state, getStorePath())
  const updateForm = () => {
    if (!setForm) {
      console.error('useStoreForm Error: setForm is not defined')
      return
    }
    if (!getStorePath()) {
      console.error('useStoreForm Error: storePath is not defined')
      return
    }
    const storeValue = getStoreValue()
    setForm(storeValue)
  }
  if (isInit) {
    updateForm()
  }
  return {
    getStoreValue,
    updateForm
  }
}

const useStoreFormChange = (useProps = {}) => {
  const setStoreDefault = (formValue, storeValue) => {
    const formValueType = toType(formValue)
    if (formValueType === 'Object') {
      return {
        ...storeValue,
        ...formValue
      }
    } else {
      return formValue
    }
  }
  const { getStorePath, curRouterStore, getForm, setStore = setStoreDefault } = useProps
  const getStoreValueByPath = (path = getStorePath()) => get(curRouterStore, path)
  const setStoreModelValue = (value) => {
    const setStoreValue = setStore(value, getStoreValueByPath())
    curRouterStore.$patch((state) => {
      set(state, getStorePath(), setStoreValue)
      return state
    })
    const firstModelPath = getFormatModelPath(getStorePath()).split('.')[0]
    const firstModelPatchValue = getStoreValueByPath(firstModelPath)
    const setLocalStoreFunName = 'set' + upperFirst(firstModelPath)
    if (curRouterStore[setLocalStoreFunName]) {
      curRouterStore[setLocalStoreFunName]({ ...firstModelPatchValue })
    } else {
      console.error(`useStoreForm Error: setLocalStoreFunName [ ${setLocalStoreFunName} ] is not defined`)
    }
  }
  const updateStore = (formValue) => {
    if (!setStore) {
      console.error('useStoreForm Error: setStore is not defined')
      return
    }
    if (!getStorePath()) {
      console.error('useStoreForm Error: storePath is not defined')
      return
    }
    setStoreModelValue(formValue)
  }
  if (getForm) {
    watch(getForm, (formValue) => {
      const copyFormValue = cloneDeep(formValue)
      updateStore(copyFormValue)
    }, {
      deep: true
    })
  }

  return {
    getStoreValueByPath,
    updateStore
  }
}

/**
 * @description: 缓存表单初始化与变化
 * @param {Function} setForm ( storeValue ): void 设置表单 结构为一个设置表单的函数
 * @param {Function} setStore ( formValue , storeValue ): nextStoreValue 设置表单 结构为一个设置store的函数 返回设置的store
 * @param {Function} getForm (): formValue 可选返回 formValue 如果存在则会监听 form变化写入缓存
 * @param {string} storePath 缓存key - [routerStoreModule]storePath
 * @param {boolean} isInit 是否初始化
 * @param {string} storeModuleName 缓存模块名称
 */
export const useStoreForm = (useProps) => {
  const { storePath = '', storeModuleName } = useProps
  let dynamicsStorePath = storePath
  const getStorePath = () => { return dynamicsStorePath }
  const updateStorePath = (newStorePath = '') => {
    if (!newStorePath) {
      console.error('useStoreForm Error: newStorePath is not defined')
      return
    }
    dynamicsStorePath = newStorePath
  }
  if (!useRouterStore) {
    console.error('useRouterStore is not defined')
    return
  }
  const curRouterStore = useRouterStore(storeModuleName)
  const initProps = { ...useProps, getStorePath, curRouterStore }
  const { getStoreValue, updateForm } = useStoreFormInit(initProps)
  const { updateStore, getStoreValueByPath } = useStoreFormChange(initProps)
  // 更新 storePath 并 初始化表单
  const updateStorePathAndForm = (newStorePath = '') => {
    updateStorePath(newStorePath)
    updateForm()
  }
  return {
    getStorePath,
    updateStorePath,
    getStoreValue,
    updateForm,
    updateStore,
    getStoreValueByPath,
    updateStorePathAndForm
  }
}

