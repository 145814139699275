// 获取默认自定义布局配置
import { SellerHomeDragConfig, VendorHomeDragConfig } from '@/const/DragConfig'

// 翻译配置
export const getHomeCompNameMap = () => ({
  'Sales Trend': $t('home.chartTitle.SalesTrend'),
  'Advertising Trend': $t('home.chartTitle.AdvertisingTrend'),
  'Top 10 Marketplaces': $t('home.chartTitle.TopTenMarketplaces'),
  'Hourly Orders': $t('home.chartTitle.HourlyOrders'),
  'Account Health & Inventory Age': $t('home.chartTitle.AccountHealthAndInventoryAge'),
  'Replenishment Advice': $t('home.chartTitle.ReplenishmentAdvice'),
  'Order Distribution Map': $t('home.chartTitle.OrderDistributionMap'),
  'Top 10 Movers': $t('home.chartTitle.topTenMovers'),
  'Order Performance': $t('home.chartTitle.OrderedPerformance'),
  'Ad Supported Sales Performance': $t('home.chartTitle.AdSalesPerformance'),
  'Traffic and Conversion Performance': $t('home.chartTitle.TrafficAndConversionPerformance'),
  'Supply Chain Performance': $t('home.chartTitle.SupplyChainPerformance'),
  'Buybox Ownership Trend': $t('home.chartTitle.BuyboxOwnershipTrend'),
  'Margin Performance': $t('home.chartTitle.MarginPerformance'),
  'Campaign Type Performance': $t('home.chartTitle.CampaignTypePerformance'),
  'Ad Performance by Weekday': $t('home.chartTitle.AdPerformanceByWeekday'),
  'Shipped Performance': $t('home.chartTitle.ShippedPerformance'),
  'Sales Forecast': $t('home.chartTitle.SalesForecast'),
  'Top 10': $t('home.chartTitle.TopTen'),
  'ASIN Efficiency': $t('home.chartTitle.ASINEfficiency'),
  'Hourly Sales': $t('home.chartTitle.HourlySales'),
  'Customer Returns': $t('common.customerReturns')
})

export function getDefaultCustomLayerConfig() {
  return SellerHomeDragConfig
}

export function getHomeMatch() {
  return VendorHomeDragConfig
}
