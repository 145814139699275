import request from '@pacvue/frame/request'
import { PacvueMessage } from '@pacvue/element-plus'
import { isCancel } from 'axios'
import { getUniqueTimeZone, isEmpty } from './common'
import { RepeatCancelMessage, ActiveCancelMessage } from './useCancelRequest'

request.use = function(fn) {
  const realFn = fn.bind(this)
  const newFn = (...args) => realFn(...args)
  newFn.use = this.use
  return newFn
}

// 是否进行错误提示 - 4001
let isTipErrorMsgBy4001 = false
let tipErrorMsgTimeoutBy4001 = null
// 节流时间
const tipErrorMsgTimeoutStepBy4001 = 2500

const TipErrorMsgBy4001Callback = (errMsg) => {
  if (isTipErrorMsgBy4001) {
    return
  }
  PacvueMessage({
    message: errMsg,
    type: 'error',
    customClass: 'pacvue-message-error'
  })
  isTipErrorMsgBy4001 = true
  if (tipErrorMsgTimeoutBy4001 !== null) {
    clearTimeout(tipErrorMsgTimeoutBy4001)
    tipErrorMsgTimeoutBy4001 = null
  }
  tipErrorMsgTimeoutBy4001 = setTimeout(() => {
    isTipErrorMsgBy4001 = false
  }, tipErrorMsgTimeoutStepBy4001)
}

request.interceptors.request.use(
  function(config) {
    if (Object.keys(config.data || {}).length) {
      config.data.uniqueTimeZone = getUniqueTimeZone()
    }
    if (Object.keys(config.params || {}).length) {
      if (!config._ignoreTimeZone) {
        config.params.uniqueTimeZone = getUniqueTimeZone()
      }
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

request.interceptors.response.use(
  function(res) {
    return Promise.resolve(res)
  },
  (err) => {
    if (isCancel(err)) {
      const errMsg = err?.message
      if (errMsg === RepeatCancelMessage ||
          errMsg === ActiveCancelMessage
      ) {
        return Promise.reject(err)
      }
      return new Promise(() => { })
    }
    // 处理 4001 重复错误处理
    const errResponse = err.data || {}

    if (errResponse.code === 4100) {
      TipErrorMsgBy4001Callback(errResponse.msg)
      return
    }

    if (err?.data) {
      const { code, msg } = err.data || {}
      const { isUserCatch } = err.config || {}
      if (code !== 200 && msg && !isUserCatch) {
        if (code === 406 || msg === 'You don\'t have permission to view it! Please refresh') {
          const uid = localStorage.getItem('uid')
          const storeKey = `${uid}_marketStoreValueMap`
          const tempStore = localStorage.getItem(storeKey)
          if (!isEmpty(tempStore)) {
            localStorage.removeItem(`${uid}_marketStoreValueMap`)
            location.reload()
          }
          return
        } else if (code === 410) {
          // sql超长后端给出提示信息
          PacvueMessage({
            message: msg,
            type: 'warning',
            customClass: 'pacvue-message-warning',
            grouping: true
          })
        } else {
          PacvueMessage({
            message: msg,
            type: 'error',
            customClass: 'pacvue-message-error',
            grouping: true
          })
        }
      }
    }
    return Promise.reject(err)
  }
)

export const UseService = function(host, params) {
  return async function(query) {
    return this({ ...query, baseURL: host, ...params })
  }
}

export const UseSameResult = (function() {
  const resolvers = []
  const rejects = []
  let currentStatus = 'FULFILLED'
  let componentNames = []
  const R = (...names) => {
    componentNames = names
    return function(vmName, query) {
      return new Promise((resolve, reject) => {
        if (componentNames.includes(vmName) && currentStatus === 'PENDING') {
          resolvers.push(resolve)
          rejects.push(reject)
          return
        }
        currentStatus = 'PENDING'
        this({
          ...query
        }).then(res => {
          currentStatus = 'FULFILLED'
          while (componentNames.length) {
            componentNames.pop()
          }
          while (resolvers.length) {
            resolvers.pop()(res)
          }
          resolve(res)
        }).catch(err => {
          currentStatus = 'FULFILLED'
          while (componentNames.length) {
            componentNames.pop()
          }
          while (rejects.length) {
            rejects.pop()(err)
          }
          reject(err)
        })
      })
    }
  }
  return R
})()

export const $request = request

export const RegisterType = {
  GLOBAL: 'global',
  PAGE: 'page'
}

export const DownloadConfig = {
  method: 'post',
  requestRegisterType: RegisterType.GLOBAL,
  fullData: true
}
