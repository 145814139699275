import {
  requestOfCallCancel_1p,
  requestOfCallCancel_3p,
  requestOfCallCancel_commerce_common,
  requestOfCallCancel_middle,
  requestOfCallCancel_productcenter,
  requestOfCallCancel_sov
} from '@!/utils/useCancelRequest'
import { downloadFactory } from '@commerceCommon/utils/download'

export * from '@commerceCommon/utils/download'
export const download3p = downloadFactory(requestOfCallCancel_3p)
export const download1p = downloadFactory(requestOfCallCancel_1p)
export const downloadSov = downloadFactory(requestOfCallCancel_sov)
export const downloadProductCenter = downloadFactory(requestOfCallCancel_productcenter)
export const downloadCommerceCommon = downloadFactory(requestOfCallCancel_commerce_common)
export const downloadMiddle = downloadFactory(requestOfCallCancel_middle)

