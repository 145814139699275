import state from './state.js'
import actions from './actions.js'
import getters from './getters.js'

export default {
  state,
  getters,
  actions
  // 暂无持久化需求
}
