import { $http3p } from '../http'
/**
 * @description 查询模板列表
 */
export const queryTemplates = (data) => {
  return $http3p({
    url: 'mailbox/template/list',
    method: 'post',
    data
  })
}
/**
 * @description 查询类型列表
 */
export const queryTypes = () => {
  return $http3p({
    url: 'mailbox/type/list',
    method: 'post'
  })
}
/**
 * @description 创建模板
 */
export const createTemplate = (data) => {
  return $http3p({
    url: 'mailbox/template/create',
    method: 'post',
    data
  })
}
/**
 * @description 查询模板详情
 */
export const queryTemplateDetail = (id) => {
  return $http3p({
    url: 'mailbox/template/detail/query',
    method: 'post',
    data: { id }
  })
}
/**
 * @description 删除模板
 */
export const deleteTemplate = (id) => {
  return $http3p({
    url: 'mailbox/template/delete',
    method: 'post',
    data: { id }
  })
}
