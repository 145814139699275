import { debounce, isElement } from 'lodash-es'

export default function(callback, ele = 'body') {
  let resizeObserver
  if (callback) {
    resizeObserver = new ResizeObserver(debounce(entries => callback(entries), 500))
    resizeObserver.observe(isElement(ele) ? ele : document.querySelector(ele))
  }
  return resizeObserver
}
