import Main from '@pacvue/frame/layout/Main.vue'
export default {
  path: '/JBP',
  component: Main,
  meta: {
    auth: true,
    id: 2
  },
  sort: 300,
  redirect: '/Business/JBP',
  children: [
    {
      path: '/Business/JBP',
      component: () => import('@!/views/JBP/Planning/index.vue'),
      meta: {
        isShowTime: false,
        showCompareCheck: false
      }
    }, {
      path: '/JBP/CreateReport',
      component: () => import('@!/views/JBP/CreateReport/index.vue'),
      meta: {
        isShowTime: false,
        showCompareCheck: false
      }
    }, {
      path: '/JBP/EditReport',
      component: () => import('@!/views/JBP/EditReport/index.vue'),
      meta: {
        isShowTime: false,
        showCompareCheck: false
      }
    }, {
      path: '/JBP/ReportDetail',
      component: () => import('@!/views/JBP/ReportDetail/index.vue'),
      meta: {
        isShowTime: false,
        showCompareCheck: false
      }
    }
  ]
}
