import { WatchFields } from './const.js'
import state from './state'
import getters from './getters'
import actions from './actions'

export default {
  state,
  getters,
  actions,
  persist: {
    enabled: true,
    isCommon: true,
    watchFields: WatchFields
  }
}
