
const state = () => {
  return {
    myReportFilterForm: {
      channelVal: [],
      reportTypes: [],
      frequency: [],
      owner: [],
      name: ''
    }
  }
}
const actions = {
  setMyReportFilterForm(formProps) {
    this.myReportFilterForm = formProps
  }
}

const WatchFields = ['myReportFilterForm']

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: true,
    watchFields: WatchFields
  }
}
