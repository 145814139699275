
const state = () => ({
  panelPlanListBy3p: [],
  panelPlanListBy1p: []
})

const actions = {
  setPanelPlanListBy3p() { },
  setPanelPlanListBy1p() { }
}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    watchFields: ['panelPlanListBy3p', 'panelPlanListBy1p']
  }
}
