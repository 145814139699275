import YoyWeek from '@!/utils/useYoyWeek'

export default function useDatePickerOptionsInit(appStore) {
  appStore.SET_LIFETIMEMODE(true)

  const yoyWeekConfig = new YoyWeek().getYoyWeekConfig()
  appStore.SET_DATEPICKEROPTIONS({
    isStrictIsolate: true,
    isIsolateRealTime: true,
    commerceCustomDateMode: true,
    yoyCustomRanges(rangeMode) {
      return yoyWeekConfig[rangeMode] || []
    }
  })
}
