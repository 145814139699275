
import { toType } from './ts-common'
export function getFilterRoutesOfType(routesConfig = []) {
  const currentRoutesConfig = []
  if (routesConfig?.length) {
    for (const routeConfigItem of routesConfig) {
      const routeConfigItemType = toType(routeConfigItem)
      // 如果 Object 直接push
      if (routeConfigItemType === 'Object') {
        currentRoutesConfig.push(routeConfigItem)
        // 如果是 Function 获取函数返回值
      } else if (routeConfigItemType === 'Function') {
        currentRoutesConfig.push(routeConfigItem())
      } else if (routeConfigItemType === 'Array') {
        // 如果是 Array 解构后push
        currentRoutesConfig.push(...routeConfigItem)
      } else {
        throw new Error(`Route Config Error:the route config item is not a vaild type of ${routeConfigItem}`)
      }
    }
  }
  return currentRoutesConfig
}
