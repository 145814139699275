import { isEmpty } from '@commerceCommon/utils/common.js'

export default function() {
  const search = window.location.search
  if (search.includes('?')) {
    const urlString = search.split('?')[1]
    if (!isEmpty(urlString)) {
      const pairObject = urlString.split('&').filter(v => v.includes('=')).reduce((total, current) => {
        const [key, value] = current.split('=')
        total[key] = value
        return total
      }, {})
      if (!isEmpty(pairObject.code, pairObject.refresh, pairObject.expires)) {
        const json = {}
        json.expires_in = new Date().getTime() + pairObject.expires * 1000
        json.access_token = pairObject.code
        json.refresh_token = pairObject.refresh
        json.token_type = 'Bearer'
        localStorage.setItem('Pacvue_token', JSON.stringify(json))
        window.location.replace(pairObject.redirect || '/')
      }
    }
  }
}
