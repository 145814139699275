import { $httpTag } from '../http'

/**
 * 获取所有tag
 * @returns {*}
 */
export const GetProductTagSellerDropData = (data) => {
  return $httpTag({
    url: 'PIM/ProductTags',
    method: 'get',
    // 不加上可能会被重复请求cancel
    isUnique: true,
    params: data
  })
}
export function GetProductTagVendorDropData(data) {
  return $httpTag({
    url: 'PIM/ProductTags',
    method: 'get',
    // 不加上可能会被重复请求cancel
    isUnique: true,
    params: data
  })
}

export const GetTagOwnerList = () => {
  return $httpTag({
    url: 'PIM/ProductTagUsers',
    method: 'get'
  })
}

export const GetProductTagSellerDropDataByUserId = (data) => {
  return $httpTag({
    url: 'PIM/GetProductTags',
    method: 'post',
    data
  })
}

/**
 * 编辑tag名称保存
 * @param {params} data
 * data: {
 *  id: tagId,
 *  tagName
 * }
 */
export function putProductTagsName(data) {
  return $httpTag({
    url: 'PIM/ProductTags/name',
    method: 'put',
    data
  })
}

/**
 * 删除tag
 * @param {params} query
 * query: {
 *  id: tagId
 * }
 */
export function deleteTags(query) {
  return $httpTag({
    url: 'PIM/ProductTags',
    method: 'delete',
    params: query
  })
}

/**
 * 创建新tag
 * @param {params} data
 * data: {
 *  isTransfer: true
    parentId: 0  添加父级标签时传0 添加sub tag传父级tagId
    tagNames: ["ltest"] 可批量添加的tag name
 * }
 */
export function postTags(data) {
  return $httpTag({
    url: 'PIM/ProductTags',
    method: 'post',
    data
  })
}

/**
 * 通过所勾选的product获取已设置的tag
 * @param {params} data
 * data: [{productId: xxx}]
 */
export function tagsByProductIdsCommerce(data) {
  return $httpTag({
    url: 'PIM/ProductTags/tagsByProductIds',
    method: 'post',
    data
  })
}

/**
 * 往所勾选的product上批量/单个添加tag
 * @param {*} data
 * @param {*} selectAll
 * data: {
 *  tagIds: [],
 *  tagProductList: []
 * }
 */
export function postToTag(data, selectAll) {
  if (selectAll) {
    return $httpTag({
      url: '3p/ProductTags/tagProducts/byConditions',
      method: 'post',
      data
    })
  } else {
    return $httpTag({
      url: 'PIM/ProductTags/products',
      method: 'post',
      data
    })
  }
}

/**
 * 通过所勾选的product删除tag
 */
export function deleteFromTag(data) {
  return $httpTag({
    url: 'PIM/ProductTags/products',
    method: 'delete',
    data
  })
}
