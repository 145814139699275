import { downloadFactory } from '@!/api/download'
import { requestOfCallCancel } from '@!/utils/useCancelRequest'
import { $http3p } from '../http'

/**
 * @template T
 * @typedef { Promise<T> } AxiosPro
 * @typedef {{ addressId: number, countryCode: string, mailAddress: string, mailBoxType: string, sellerId: string, amazonEmail: number, draft: number, inbox: number, sent: number, total: number }} Email
 */

/**
 * Mailbox - 获取支持绑定的邮箱列表
 * @returns { AxiosPro<string[]> } res
 */
export const querySupportMailboxTypes = () => {
  return $http3p({
    url: 'mailbox/getSupportMailboxTypes',
    method: 'post'
  })
}

/**
 * Mailbox - 创建邮箱绑定
 * @param {{ authorizationCode: string, countryCode: string, emailAddress: string, mailBoxType: string, sellerId: string }} data
 * @returns { AxiosPro<{connectionSucceed: boolean, errorMessage: string}> } res
 */
export const createMailboxConnection = (data) => {
  return $http3p({
    url: 'mailbox/connection',
    method: 'post',
    data
  })
}

/**
 * Mailbox - query encrypt public key
 * @returns { AxiosPro<string> } res
 */
export const queryPublicKey = () => {
  return $http3p({
    url: 'mailbox/getConnectionKey',
    method: 'post'
  })
}

/**
 * Mailbox - 创建邮箱绑定
 * @param {{ authorizationCode: string, addressId: Email.addressId }} data
 * @returns { AxiosPro<{connectionSucceed: boolean, errorMessage: string}> } res
 */
export const reConnection = (data) => {
  return $http3p({
    url: 'mailbox/reConnection',
    method: 'post',
    data
  })
}

/**
 * Mailbox - 弹框内的已绑定邮箱列表
 * @typedef { addressId: Email.addressId, associatedTime: string, countryCode: Email.countryCode, emailAddress: Email.mailAddress, mailAddressStatus: string, sellerId: Email.sellerId, mailboxType: string  } ConnectedMailboxType
 * @param {{ countryCodes: string[], sellerIds: string[] }} data
 * @returns { AxiosPro<ConnectedMailboxType[]> } res
 */
export const queryMailboxConnnections = (data) => {
  return $http3p({
    url: 'mailbox/address/list',
    method: 'post',
    data
  })
}

/**
 * Mailbox - 移除一个已绑定的邮箱账号
 * @param {{ addressId: Email.addressId,countryCode: string, sellerId: string }} data
 * @returns { AxiosPro<any> } res
 */
export const removeConnectedMailAddress = (data) => {
  return $http3p({
    url: 'mailbox/deleteMailAddress',
    method: 'post',
    data
  })
}

/**
 * Mailbox - 同步邮件(包括站内信)功能
 * @param {{ countryCodes: Email.countryCode[], sellerIds: Email.sellerId[], addressIds: Email.addressId[] }} data
 * @returns { AxiosPro<{ failedAddressIds: string[], finishTime: string }> } res
 */
export const synchronizeMailbox = (data) => {
  return $http3p({
    url: 'mailbox/synchronizeMessages',
    method: 'post',
    data,
    // 目前5分钟自动请求一次
    // 接口时间反应时间与邮件数量正相关，5分钟未出结果直接取消重新请求
    // 设定超时配置大于5分钟，是为了不报超时
    timeout: 320000
  })
}

/**
 * Mailbox - 获取邮箱文件夹结构：包括所有MailAddress、RecordsOfAutomationRules的数量、AmazonMessages的数量
 * @param {{ countryCodes: string[], sellerIds: string[] }} data
 * @returns { AxiosPro<{ amazonMessages: number, emailAddress: Email[], recordsOfAutomationRules: number }> } res
 */
export const queryMailboxFolder = (data) => {
  return $http3p({
    url: 'mailbox/messageFolders',
    method: 'post',
    data
  })
}

/**
 * Mailbox - 获取站内信列表
 * @typedef { 'Today' | 'Yesterday' | 'Last Week' | 'Two Weeks Ago' | 'Thress Weeks Ago' | 'Earlier This Month' | 'Last Month' | 'Older' } TimeTag
 * @typedef { 'Replied On Amazon' | 'No Response Needed' | 'Unread' | 'Read' | 'Replied' } MessageStatus
 * @typedef {{ customerName: string, messageId: string, orderId: string, messageStatus: MessageStatus, messageSubject: string, replyDeadLineSeconds: number, sendDate: string, timeTag: TimeTag, sellerId: string, countryCode: string, showDeadLine: boolean }} Message
 * @param {{ countryCodes: Email.countryCode[], pageNum: number, pageSize: number, sellerIds: Email.sellerId[], sort: string, sortField: string }} data
 * @returns { AxiosPro<{ list: Message[], total: number }> } res
 */
export const queryMessageList = (data) => {
  return requestOfCallCancel({
    url: 'mailbox/messageList',
    method: 'post',
    data
  })
}

/**
 * @description Mailbox - 获取自动执行的邮件列表
 */
export const queryAutomationRecords = (data) => {
  return requestOfCallCancel({
    url: 'mailbox/automationRecords',
    method: 'post',
    data
  })
}

/**
 * Mailbox - 获取站内信详情
 * @typedef {{ contentType: string, fileName: string, fileType: string, fileUploadType: string, fileUrl: string }} Attachment
 * @typedef {{ attachments: Attachment[], messageContent: string, messageId: Message.messageId, sendTime: string, senderAddress: string }} MessageDetail
 * @param {{ countryCode: Email.countryCode, sellerId: Email.sellerId, messageId: Message.messageId }} data
 * @returns { AxiosPro<MessageDetail[]> } res
 */
export const queryMessageDetail = (data) => {
  return $http3p({
    url: 'mailbox/amazonMessageDetail',
    method: 'post',
    data
  })
}

/**
 * Mailbox - 发送站内信
 * @typedef {{ attachments: Attachment[], messageContent: string, replyMessageId: Message.messageId, countryCode: string, sellerId: string }} ReplyMessageDetail
 * @param { ReplyMessageDetail } data
 * @returns { AxiosPro<{ replyTime: string }> } res
 */
export const replyMessage = (data) => {
  return $http3p({
    url: 'mailbox/replyMessage',
    method: 'post',
    data,
    isUnique: true
  })
}

/**
 * Mailbox - 更新一个站内信的状态 从这个接口改变的状态只能是 'Replied On Amazon' | 'No Response Needed' | 'Unread'
 * @param {{ countryCode: string, messageId: string, messageStatus: 'Replied On Amazon' | 'No Response Needed' | 'Unread', sellerId: string }} data
 * @returns { AxiosPro<null> } res
 */
export const updateMessageStatus = (data) => {
  return $http3p({
    url: 'mailbox/updateMessageStatus',
    method: 'post',
    data
  })
}

/**
 * Mailbox - 下载一个附件
 * @param {{ attachmentInfo: Attachment, countryCode: string, messageId: string, sellerId: string }} data
 * @returns { AxiosPro<Blob> } res
 */
export const downloadAttachment = (data) => {
  return downloadFactory($http3p)(
    'mailbox/message/attachment/download',
    data,
    null,
    null
  )
}

/**
 * Mailbox - 下载一个图片（用于回显）
 * @param { Attachment } data
 * @returns { AxiosPro<string> } res
 */
export const downloadImage = (data) => {
  return $http3p({
    url: 'mailbox/message/attachment/image/download',
    method: 'post',
    data: {
      attachmentInfo: data
    },
    isUnique: true
  })
}

/**
 * Mailbox - 上传一个附件
 * @param { FormData } data
 * @returns { AxiosPro<Attachment> } res
 */
export const uploadAttachment = (data) => {
  return $http3p({
    url: 'mailbox/message/attachment/upload',
    method: 'post',
    headers: {
      contentType: 'multipart/form-data'
    },
    data
  })
}

/**
 * Mailbox - Order Details 获取站内信的订单详情
 * @typedef {{ asin: string, countryCode: string, imageUrl: string, price: string, productName: string, quantity: string, sku: string }} ProductInfoType
 * @typedef {{ fulfillmentChannel: string, orderId: string, orderStatus: string, productInfos: ProductInfoType[], purchaseData: string, shippedDate: string, purchaseDate: string }} OrderDetailType
 * @param {{ orderId: string }} data
 * @returns { AxiosPro<{ list: OrderDetailType }> } res
 */

export const queryOrderDetails = (data) => {
  return $http3p({
    url: 'mailbox/orderDetails',
    method: 'post',
    data
  })
}

/**
 * Mailbox - Follow-Up History 获取站内信的跟进历史
 * @typedef {{ followUpDescribe: string, followUpTime: string }} FollowUpHistoryType
 * @param {{ countryCode: Email.countryCode, sellerId: Email.sellerId, messageId: string }} data
 * @returns { AxiosPro<FollowUpHistoryType[]> } res
 */

export const queryFollowUpHistory = (data) => {
  return $http3p({
    url: 'mailbox/followUpHistory',
    method: 'post',
    data
  })
}

/**
 * Mailbox - 获取某个addressId下的邮件列表
 * @typedef {{ fromName: string, messageId: string, orderId: string, messageStatus: MessageStatus, messageSubject: string, sendDate: string, timeTag: TimeTag, sellerId: string, countryCode: string }} Mail
 * @param {{ addressId: Email.addressId, countryCode: Email.countryCode, messageType: ,pageNum: number, pageSize: number, sellerId: Email.sellerId, sort: string, sortField: string }} data
 * @returns { AxiosPro<{ list: Mail[], total: number }> } res
 */
export const queryMailList = (data) => {
  return requestOfCallCancel({
    url: 'mailbox/emailList',
    method: 'post',
    data
  })
}

/**
 * Mailbox - 获取某个messageId的邮件详情
 * @typedef {{ messageContent: string, messageSubject: string, receiverAddress: string, sendTime: string, senderAddress: string }} MailDetail
 * @param {{ messageId: Mail.messageId, countryCode: Email.countryCode, sellerId: Email.sellerId }} data
 * @returns { AxiosPro<{ MailDetail }> } res
 */
export const queryMailDetail = (data) => {
  return $http3p({
    url: 'mailbox/emailDetail',
    method: 'post',
    data
  })
}

/**
 * Mailbox - 获取某个规则详情
 */
export const queryRuleDetail = (data) => {
  return $http3p({
    url: 'mailbox/automationRecord/detail',
    method: 'post',
    data
  })
}

/**
 * Mailbox - 获取当前上传附件大小/邮件发送大小的限制
 * @returns { AxiosPro<number> } res
 */
export const queryUploadLimitSize = () => {
  return $http3p({
    url: 'mailbox/getReplyMailMaxBytes',
    method: 'post'
  })
}
