export const downloadBlob = (res) => {
  const blob = new Blob([res.data], { type: res?.data?.type })
  const downloadElement = document.createElement('a')
  const href = window.URL.createObjectURL(blob) // 创建下载的链接
  downloadElement.href = href
  downloadElement.download = res.fileName // 下载后文件名
  document.body.appendChild(downloadElement)
  downloadElement.click() // 点击下载
  document.body.removeChild(downloadElement) // 下载完成移除元素
  window.URL.revokeObjectURL(href) // 释放掉blob对象
}

export const downloadUrl = (href, fileName) => {
  const downloadElement = document.createElement('a')
  downloadElement.href = href
  if (fileName) {
    downloadElement.download = `${fileName}.xlsx`
  }
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
  window.URL.revokeObjectURL(href)
}

export const downloadFactory = RequestService => (url, data, fileName = '', otherConfig = {}) => {
  return new Promise((resolve, reject) => {
    RequestService({
      url,
      method: 'post',
      responseType: 'blob',
      data,
      fullData: true,
      _ignoreRouterProxy: true,
      ...otherConfig
    })
      .then((res) => {
        const disposition = res.headers['content-disposition']
        if (res.data && (fileName || disposition)) {
          res.fileName = decodeURIComponent(fileName || disposition.substring(
            disposition.indexOf('filename=') + 9,
            disposition.length
          ))
          downloadBlob(res)
          resolve({ data: { fileName: res.fileName, code: 200, isBlob: true }})
        } else {
          resolve({ data: { fileName: res.fileName || '', code: 400, isBlob: true }})
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

