import { $http3p, DownloadConfig } from '../http'

export const getClaimList = (data) => {
  return $http3p({
    url: 'claim/manage/query/claims/list',
    method: 'post',
    data
  })
}

export const getAsyncClaimStatus = (data) => {
  return $http3p({
    url: 'claim/manage/enum/list',
    method: 'post',
    data
  })
}

export function ExportClaimApi(data) {
  return $http3p({
    url: 'claim/manage/claims/list/download',
    data: {
      ...data,
      pageNum: 0,
      pageSize: 0
    },
    ...DownloadConfig
  })
}

export function InboundConfirm(data) {
  return $http3p({
    url: 'claim/manage/edit/inbound/commerce/approval',
    method: 'post',
    data
  })
}

export function GetConfirmFileUrl(data) {
  return $http3p({
    url: 'claim/manage/query/claims/inbound/link',
    method: 'post',
    data
  })
}

// 是否有手动权限
export function getManualPermission() {
  return $http3p({
    url: 'claim/manage/manualPermission',
    method: 'get'
  })
}

// 手动开case
export function manualCase(data) {
  return $http3p({
    url: 'claim/manage/manualCase',
    method: 'post',
    data
  })
}

// 获取claim模板
export function getCaseTemplate(data) {
  return $http3p({
    url: 'claim/manage/getCaseModule',
    method: 'post',
    data
  })
}

export const getChoosePage = () => {
  return $http3p({
    url: 'claim/manage/claims/fba/choose/page',
    method: 'post'
  })
}

export const getPacvueGuide = () => {
  return $http3p({
    url: 'claim/manage/query/claims/fba/has/data',
    method: 'post'
  })
}

export const fbaFeeDetail = (data) => {
  return $http3p({
    url: 'fbaFee/detail',
    method: 'GET',
    params: data
  })
}

// fbaFee/confirmRecord
export const fbaFeeConfirmRecord = (data) => {
  return $http3p({
    url: 'fbaFee/confirmRecord',
    method: 'post',
    data
  })
}

// fbaFee/updateConfirmRecord
export const fbaFeeUpdateConfirmRecord = (data) => {
  return $http3p({
    url: 'fbaFee/updateConfirmRecord',
    method: 'post',
    data
  })
}

/**
 * 检测用户是否开启Inbound Full Service模式
 */
export function checkIsFullServiceApi() {
  return $http3p({
    url: 'claim/inbound/query/commerce/checkNonFullService',
    method: 'post'
  })
}

/**
 * 用户确认开启Inbound Full Service模式
 */
export function confirmFullServiceApi() {
  return $http3p({
    url: 'claim/inbound/confirm/commerce/agreeFullService',
    method: 'post'
  })
}
