const defaultShareStates = {
  market: [],
  store: [],
  dateRange: {},
  compareTime: [],
  showDateCompare: false,
  isCompare: false,
  compareSummary: false,
  isCompareCustom: false,
  latestDateMode: null,
  datepickerOptions: {
    isStrictIsolate: true,
    isIsolateRealTime: true,
    commerceCustomDateMode: true
  }
}
export default {
  // 用于存面包屑右侧的状态
  setShareStates(shareId, data = {}) {
    if (!this.shareStates[shareId]) {
      this.shareStates[shareId] = defaultShareStates
    }
    Object.keys(data).forEach(key => {
      this.shareStates[shareId][key] = data[key]
    })
  },
  getShareStates(shareId) {
    return this.shareStates[shareId] ?? null
  },
  SET_DATERANGE(shareId, data) {
    this.shareStates[shareId].dateRange = data
  },
  SET_COMPARETIME(shareId, data) {
    this.shareStates[shareId].compareTime = data
  },
  SET_SHOWDATECAMPRE(shareId, data) {
    this.shareStates[shareId].showDateCompare = data
  },
  SET_ISCOMPARE(shareId, data) {
    this.shareStates[shareId].isCompare = data
  },
  SET_COMPARESUMMARY(shareId, data) {
    this.shareStates[shareId].compareSummary = data
  },
  SET_ISCOMPARECUSTOM(shareId, data) {
    this.shareStates[shareId].isCompareCustom = data
  },
  SET_LATESTDATEMODE(shareId, data) {
    this.shareStates[shareId].latestDateMode = data
  },
  SET_DATEPICKEROPTIONS(shareId, data) {
    this.shareStates[shareId].datepickerOptions = data
  },
  // 用于存创建ShareLink时候的数据
  setShareLinkData(shareId, data) {
    this.shareLinkData[shareId] = data
  },
  getShareLinkData(shareId) {
    return this.shareLinkData[shareId] ?? null
  },
  // 用于存Share的全局下拉组件的Option
  setShareDropData(shareId, data) {
    this.shareDropData[shareId] = data
  },
  getShareDropData(shareId) {
    return this.shareDropData[shareId] ?? null
  },
  setIsAllAsyncDataComplete(val) {
    this.isAllAsyncDataComplete = val
  }
}
