export default {
  retailer: false,
  account: false,
  swtichOld: false,
  University: false,
  Training: false,
  Knowledge: true,
  Feedback: true,
  Message: true,
  Synchronization: false,
  MyAccount: true,
  UserManage: true,
  Settings: true,
  Notification: false,
  UserCenter: true,
  availablePlatforms: {},
  analytics: true,
  Language: [
    {
      id: 'EN',
      url: 'https://pacvue-public-doc.s3.us-west-2.amazonaws.com/oss/pacvue-cdn/country/US.png',
      name: 'English'
    },
    {
      id: 'JA',
      url: 'https://pacvue-public-doc.s3.us-west-2.amazonaws.com/oss/pacvue-cdn/country/JP.png',
      name: '日本語'
    },
    {
      id: 'ZH',
      url: 'https://pacvue-public-doc.s3.us-west-2.amazonaws.com/oss/pacvue-cdn/country/ZH.png',
      name: '中文'
    }
  ],
  RRM: true,
  noGlobalAnnouncement: true, // 是否需要路由切换后调用 admin-middle 的 query/announcement 全局通告查询接口
  // 采集测试实例配置[applicationId, clientToken, service, blacklist<"1,2,3">]，默认空则启用生产实例
  ddConfig: []
}
