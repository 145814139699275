const PeriscopeConfigMarket3PKey = 'PeriscopeConfigMarket3P'
const PeriscopeConfigMarket1PKey = 'PeriscopeConfigMarket1P'
const PeriscopeConfigStore3PKey = 'PeriscopeConfigStore3P'
const PeriscopeConfigStore1PKey = 'PeriscopeConfigStore1P'
const PeriscopeConfigCategory3PKey = 'PeriscopeConfigCategory3P'
const PeriscopeConfigCategory1PKey = 'PeriscopeConfigCategory1P'
const PeriscopeConfigBrand3PKey = 'PeriscopeConfigBrand3P'
const PeriscopeConfigBrand1PKey = 'PeriscopeConfigBrand1P'

const state = () => ({
  // periscope单选配置
  countryCode_vendor: sessionStorage.getItem(PeriscopeConfigMarket1PKey) || '',
  countryCode_seller: sessionStorage.getItem(PeriscopeConfigMarket3PKey) || '',
  sellerId: sessionStorage.getItem(PeriscopeConfigStore3PKey) || '',
  vendorGroupId: sessionStorage.getItem(PeriscopeConfigStore1PKey) || '',
  category_vendor: JSON.parse(sessionStorage.getItem(PeriscopeConfigCategory1PKey) || '[]'),
  category_seller: JSON.parse(sessionStorage.getItem(PeriscopeConfigCategory3PKey) || '[]'),
  brand_vendor: JSON.parse(sessionStorage.getItem(PeriscopeConfigBrand1PKey) || '[]'),
  brand_seller: JSON.parse(sessionStorage.getItem(PeriscopeConfigBrand3PKey) || '[]')
})

const actions = {
  // 设置vendor
  setVendorConfig(payload) {
    const { market, store, brand, category } = payload
    this.countryCode_vendor = market
    this.vendorGroupId = store
    sessionStorage.setItem(PeriscopeConfigMarket1PKey, market)
    sessionStorage.setItem(PeriscopeConfigStore1PKey, store)
    if (brand) {
      this.brand_vendor = brand
      sessionStorage.setItem(PeriscopeConfigBrand1PKey, JSON.stringify(brand))
    }
    if (category) {
      this.category_vendor = category
      sessionStorage.setItem(PeriscopeConfigCategory1PKey, JSON.stringify(category))
    }
  },
  // 设置seller
  setSellerConfig(payload) {
    const { market, store, brand, category } = payload
    this.countryCode_seller = market
    this.sellerId = store
    sessionStorage.setItem(PeriscopeConfigMarket3PKey, market)
    sessionStorage.setItem(PeriscopeConfigStore3PKey, store)
    if (brand) {
      this.brand_seller = brand
      sessionStorage.setItem(PeriscopeConfigBrand3PKey, JSON.stringify(brand))
    }
    if (category) {
      this.category_seller = category
      sessionStorage.setItem(PeriscopeConfigCategory3PKey, JSON.stringify(category))
    }
  }
}
export default {
  state,
  actions
}

