import useResizeObserver from '@commerceCommon/utils/resizeObserver.js'
import { useCommonStore } from '@#/store/index.js'

export default function() {
  const commonStore = useCommonStore()
  useResizeObserver((entity) => {
    const tempContentWidth = entity[0].contentRect.width
    commonStore.isSmallScreen = tempContentWidth < 1600
  }, '#vm')
}
