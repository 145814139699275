import { getCustomLayerConfig, updateCustomLayerConfig } from '@!/api/Home/index.js'
import * as HomeConst from '@!/const/HomeComp.js'
import { updateUserCustomJson, getUserCustomJson } from '@!/api/Account/common'
import { TimeDimensionEnum, TDByTodaySettingEnum, TopTenShakersSortTypeEnum, TopTenShakersChainTypeEnum } from '@!/const/Home'
import { diffCustomLayerConfig } from '@commerceCommon/utils/customLayer'

const getUID = () => localStorage.getItem('uid')
const stateKeys = {
  get customLayerConfigKey() {
    return getUID() + '_3p_homeCustomLayerConfig'
  },
  get vendorConfigKey() {
    return getUID() + '_1p_homeVendorConfig'
  },
  get timeDimensionBySalesKey() {
    return getUID() + '_3p_timeDimensionBySales'
  },
  get tdBySalesTodaySettingKey() {
    return getUID() + '_3p_tdBySalesTodaySetting'
  },
  get topShakersSortTypeKey_3p() {
    return getUID() + '_3p_topShakersSortType'
  },
  get topShakersSortTypeKey_1p() {
    return getUID() + '_1p_topShakersSortType'
  },
  get topShakersChainTypeKey_3p() {
    return getUID() + '_3p_topShakersChainType'
  },
  get topShakersChainTypeKey_1p() {
    return getUID() + '_1p_topShakersChainType'
  },
  get InventoryOverview_1p() {
    return getUID() + '_1p_inventoryOverview'
  },
  get InventoryOverview_3p() {
    return getUID() + '_3p_inventoryOverview'
  }
}

const state = () => ({
  // 自定义布局配置
  customLayerConfig:
    JSON.parse(sessionStorage.getItem(stateKeys.customLayerConfigKey) || 'null') ||
    HomeConst.getDefaultCustomLayerConfig(),
  vendorConfig:
    JSON.parse(sessionStorage.getItem(stateKeys.vendorConfigKey) || 'null') || [],
  // sales Type
  timeDimensionBySales: localStorage.getItem(stateKeys.timeDimensionBySalesKey) || TimeDimensionEnum.P,
  // sales TimeDimension.Today -- setting
  tdBySalesTodaySetting: localStorage.getItem(stateKeys.tdBySalesTodaySettingKey) || TDByTodaySettingEnum.Cumulative,
  // Seller Top Shakers sortType
  sellerTopShakersSortType: localStorage.getItem(stateKeys.topShakersSortTypeKey_3p),
  // Vendor Top Shakers sortType
  vendorTopShakersSortType: localStorage.getItem(stateKeys.topShakersSortTypeKey_1p),
  // Seller Top Shakers sortType
  sellerTopShakersChainType: localStorage.getItem(stateKeys.topShakersChainTypeKey_3p),
  // Vendor Top Shakers sortType
  vendorTopShakersChainType: localStorage.getItem(stateKeys.topShakersChainTypeKey_1p),
  vendorInventoryOverview: JSON.parse(sessionStorage.getItem(stateKeys.InventoryOverview_1p) || 'null') || [],
  sellerInventoryOverview: JSON.parse(sessionStorage.getItem(stateKeys.InventoryOverview_3p) || 'null') || []
})

const actions = {
  // home top Sales Widget
  setTimeDimensionBySales(timeDimension, isUpdateOrigin = true) {
    const defaultTimeDimension = timeDimension || TimeDimensionEnum.P
    this.timeDimensionBySales = defaultTimeDimension
    localStorage.setItem(stateKeys.timeDimensionBySalesKey, defaultTimeDimension)
    if (isUpdateOrigin) {
      updateUserCustomJson('timeDimensionBySales', defaultTimeDimension)
    }
  },
  async getTimeDimensionBySalesPro(isUpdateStore = true) {
    if (this.timeDimensionBySales) {
      return Promise.resolve(this.timeDimensionBySales)
    }
    return getUserCustomJson('timeDimensionBySales')
      .then(res => {
        if (isUpdateStore) {
          this.setTimeDimensionBySales(res, !res)
        }
        return Promise.resolve(res)
      }).catch(err => {
        return Promise.reject(err)
      })
  },
  setTdBySalesTodaySetting(settingVal, isUpdateOrigin = true) {
    this.tdBySalesTodaySetting = settingVal || TDByTodaySettingEnum.Cumulative
    localStorage.setItem(stateKeys.tdBySalesTodaySettingKey, this.tdBySalesTodaySetting)
    if (isUpdateOrigin) {
      updateUserCustomJson('tdBySalesTodaySetting', this.tdBySalesTodaySetting)
    }
  },
  async getTdBySalesTodaySettingPro(isUpdateStore = true) {
    if (this.tdBySalesTodaySetting) {
      return Promise.resolve(this.tdBySalesTodaySetting)
    }
    return getUserCustomJson('tdBySalesTodaySetting')
      .then(res => {
        if (isUpdateStore) {
          this.setTdBySalesTodaySetting(res, !res)
        }
        return Promise.resolve(res)
      }).catch(err => {
        return Promise.reject(err)
      })
  },
  // 设置seller Top Ten Movers SortType
  setSellerTopTenMoversSortType(settingVal, isUpdateOrigin = true) {
    this.sellerTopShakersSortType = settingVal || TopTenShakersSortTypeEnum.Top
    localStorage.setItem(stateKeys.topShakersSortTypeKey_3p, this.sellerTopShakersSortType)
    if (isUpdateOrigin) {
      updateUserCustomJson('sellerTopShakersSortType', this.sellerTopShakersSortType)
    }
  },
  async getSellerTopTenMoversSortType(isUpdateStore = true) {
    if (this.sellerTopShakersSortType) {
      return Promise.resolve(this.sellerTopShakersSortType)
    }
    return getUserCustomJson('sellerTopShakersSortType')
      .then(res => {
        if (isUpdateStore) {
          this.setSellerTopTenMoversSortType(res, !res)
        }
        return Promise.resolve(res)
      }).catch(err => {
        return Promise.reject(err)
      })
  },
  // 设置vender Top Ten Movers SortType
  setVendorTopTenMoversSortType(settingVal, isUpdateOrigin = true) {
    this.vendorTopShakersSortType = settingVal || TopTenShakersSortTypeEnum.Top
    localStorage.setItem(stateKeys.topShakersSortTypeKey_1p, this.vendorTopShakersSortType)
    if (isUpdateOrigin) {
      updateUserCustomJson('vendorTopShakersSortType', this.vendorTopShakersSortType)
    }
  },
  async getVendorTopTenMoversSortType(isUpdateStore = true) {
    if (this.vendorTopShakersSortType) {
      return Promise.resolve(this.vendorTopShakersSortType)
    }
    return getUserCustomJson('vendorTopShakersSortType')
      .then(res => {
        if (isUpdateStore) {
          this.setVendorTopTenMoversSortType(res, !res)
        }
        return Promise.resolve(res)
      }).catch(err => {
        return Promise.reject(err)
      })
  },
  // 设置seller Top Ten Movers ChainType
  setSellerTopTenMoversChainType(settingVal, isUpdateOrigin = true) {
    this.sellerTopShakersChainType = settingVal || TopTenShakersChainTypeEnum.PoP
    localStorage.setItem(stateKeys.topShakersChainTypeKey_3p, this.sellerTopShakersChainType)
    if (isUpdateOrigin) {
      updateUserCustomJson('sellerTopShakersChainType', this.sellerTopShakersChainType)
    }
  },
  async getSellerTopTenMoversChainType(isUpdateStore = true) {
    if (this.sellerTopShakersChainType) {
      return Promise.resolve(this.sellerTopShakersChainType)
    }
    return getUserCustomJson('sellerTopShakersChainType')
      .then(res => {
        if (isUpdateStore) {
          this.setSellerTopTenMoversChainType(res, !res)
        }
        return Promise.resolve(res)
      }).catch(err => {
        return Promise.reject(err)
      })
  },
  // 设置vender Top Ten Movers ChainType
  setVendorTopTenMoversChainType(settingVal, isUpdateOrigin = true) {
    this.vendorTopShakersChainType = settingVal || TopTenShakersChainTypeEnum.PoP
    localStorage.setItem(stateKeys.topShakersChainTypeKey_1p, this.vendorTopShakersChainType)
    if (isUpdateOrigin) {
      updateUserCustomJson('vendorTopShakersChainType', this.vendorTopShakersChainType)
    }
  },
  async getVendorTopTenMoversChainType(isUpdateStore = true) {
    if (this.vendorTopShakersChainType) {
      return Promise.resolve(this.vendorTopShakersChainType)
    }
    return getUserCustomJson('vendorTopShakersChainType')
      .then(res => {
        if (isUpdateStore) {
          this.setVendorTopTenMoversChainType(res, !res)
        }
        return Promise.resolve(res)
      }).catch(err => {
        return Promise.reject(err)
      })
  },
  // 设置vendor自定义布局配置
  setVendorLayerConfig(configProp) {
    this.vendorConfig = configProp
    sessionStorage.setItem(stateKeys.vendorConfigKey, JSON.stringify(configProp))
  },
  // 设置自定义布局配置
  setCustomLayerConfig(configProp) {
    this.customLayerConfig = configProp
    sessionStorage.setItem(stateKeys.customLayerConfigKey, JSON.stringify(configProp))
  },
  // 获取自定义布局配置
  getCustomLayerConfigPro() {
    const layerConfigStorage = sessionStorage.getItem(stateKeys.customLayerConfigKey)
    if (layerConfigStorage) {
      return Promise.resolve(JSON.parse(layerConfigStorage))
    }
    return getCustomLayerConfig()
      .then(res => {
        const originCustomLayerConfig = res ? [...JSON.parse(res)] : []
        const localCustomLayerConfig = HomeConst.getDefaultCustomLayerConfig()
        const { nextCustomLayerConfig, isUpdateOriginCustomLayer } = diffCustomLayerConfig(originCustomLayerConfig, localCustomLayerConfig)
        // 更新远程布局
        if (isUpdateOriginCustomLayer) {
          console.log('update origin customLayerConfig')
          updateCustomLayerConfig({
            detail: JSON.stringify(nextCustomLayerConfig)
          })
        }
        console.log('set store customLayerConfig', nextCustomLayerConfig)
        this.setCustomLayerConfig(nextCustomLayerConfig)
        return Promise.resolve(nextCustomLayerConfig)
      }).catch(err => {
        return Promise.reject(err)
      })
  },
  // 设置vendor自定义布局配置
  setVendorInventoryOverviewDrag(configProp) {
    this.vendorInventoryOverview = configProp
    sessionStorage.setItem(stateKeys.InventoryOverview_1p, JSON.stringify(configProp))
  },
  // 设置vendor自定义布局配置
  setSellerInventoryOverviewDrag(configProp) {
    this.sellerInventoryOverview = configProp
    sessionStorage.setItem(stateKeys.InventoryOverview_3p, JSON.stringify(configProp))
  }
}
export default {
  state,
  actions
}

