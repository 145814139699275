import { creatCancelRequest } from '@commerceCommon/utils/useCancelRequest'
export * from '@commerceCommon/utils/useCancelRequest'
const { VITE_APP_DATA_3P
  , VITE_APP_DATA_1P
  , VITE_APP_SOVURL
  , VITE_APP_PRODUCT_CENTER
  , VITE_APP_DOWNLOADCENTER
  , VITE_APP_ADMIN_MIDDLE
  , VITE_APP_COMMERCE_COMMON
} = import.meta.env

// 可取消请求实例
export const requestOfCallCancel_3p = creatCancelRequest({
  baseURL: VITE_APP_DATA_3P,
  headers: {
    'platform-type': 3
  }

})
export const requestOfCallCancel = requestOfCallCancel_3p // ( 历史遗留 默认指向 3P )
export const requestOfCallCancel_1p = creatCancelRequest({
  baseURL: VITE_APP_DATA_1P,
  headers: {
    'platform-type': 1
  }
})
export const requestOfCallCancel_sov = creatCancelRequest({
  baseURL: VITE_APP_SOVURL
})
export const requestOfCallCancel_productcenter = creatCancelRequest({
  baseURL: VITE_APP_PRODUCT_CENTER
})
/**
 * @description: 新下载中心
 */
export const requestByDownloadCenter = creatCancelRequest({
  baseURL: VITE_APP_DOWNLOADCENTER
})

export const requestOfCallCancel_commerce_common = creatCancelRequest({
  baseURL: VITE_APP_COMMERCE_COMMON
})

export const requestOfCallCancel_middle = creatCancelRequest({
  baseURL: VITE_APP_ADMIN_MIDDLE
})

