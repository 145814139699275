import { defineStore } from 'pinia'
export function strCapitalize(str) {
  str = str || ''
  if (Object.prototype.toString.call(str) === '[object String]') {
    str = str.charAt(0).toUpperCase() + str.slice(1)
  }
  return str
}

export function useStoreModule(modules) {
  const storeModule = {}
  const storedModule = {}
  // 生成module以use+模块名称+'Store'
  for (const [key, module] of Object.entries(modules)) {
    const id = 'use' + strCapitalize(key) + 'Store'
    storeModule[id] = () => {
      if (!storedModule[key]) {
        storedModule[key] = defineStore(key, module)()
      }
      return storedModule[key]
    }
  }
  return storeModule
}

export function getUseStoreKey(moduleName) {
  return 'use' + strCapitalize(moduleName) + 'Store'
}
export function getIsTopTen(clientId) {
  return [3026, 62].includes(clientId)
}
