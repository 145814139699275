// @ts-expect-error: no type define.
import Main from '@pacvue/frame/layout/Main.vue'
export default {
  path: '/Report',
  component: Main,
  meta: {
    auth: true,
    id: 666
  },
  sort: 900,
  redirect: '/Reporting/DefaultReport',
  children: [
    {
      path: '/Reporting/DefaultReport',
      component: () => import('@!/views/Report/DefaultReport/index.vue'),
      name: 'DefaultReport',
      meta: {
        autoTestPrefix: 'auto_bm147'
      }
    },
    {
      path: '/Report/VendorAccountReport',
      component: () => import('@!/views/Report/DefaultReport/VendorAccountReport.vue'),
      props: route => ({ type: route.query.type, channel: route.query.channel }),
      meta: {
        autoTestPrefix: 'auto_bm148'
      }
    },
    {
      path: '/Report/ASINReport',
      component: () => import('@!/views/Report/DefaultReport/ASINReport.vue'),
      props: route => ({ type: route.query.type, channel: route.query.channel }),
      meta: {
        autoTestPrefix: 'auto_bm149'
      }
    },
    {
      path: '/Report/MarketReport',
      component: () => import('@!/views/Report/DefaultReport/MarketReport.vue'),
      props: route => ({ type: route.query.type, channel: route.query.channel }),
      meta: {
        autoTestPrefix: 'auto_bm150'
      }
    },
    {
      path: '/Report/BrandReport',
      component: () => import('@!/views/Report/DefaultReport/BrandReport.vue'),
      props: route => ({ type: route.query.type, channel: route.query.channel }),
      meta: {
        autoTestPrefix: 'auto_bm151'
      }
    },
    {
      path: '/Report/CategoryReport',
      component: () => import('@!/views/Report/DefaultReport/CategoryReport.vue'),
      props: route => ({ type: route.query.type, channel: route.query.channel }),
      meta: {
        autoTestPrefix: 'auto_bm152'
      }
    },
    {
      path: '/Report/StageComparisonReport',
      component: () => import('@!/views/Report/DefaultReport/StageComparisonReport.vue'),
      props: route => ({ type: route.query.type, channel: route.query.channel }),
      meta: {
        autoTestPrefix: 'auto_bm153'
      }
    },
    // StageComparisonReport
    {
      path: '/Report/PeriscopeReport',
      component: () => import('@!/views/Business/Periscope/index.vue'),
      props: route => ({ type: route.query.type, channel: route.query.channel }),
      meta: {
        autoTestPrefix: 'auto_bm154'
      }
    },
    {
      path: '/Report/ReckittReport',
      component: () => import('@!/views/Report/DefaultReport/ReckittReport.vue'),
      props: route => ({ type: route.query.type, channel: route.query.channel }),
      meta: {
        autoTestPrefix: 'auto_bm155'
      }
    },
    // RepeatPurchaseBehavior
    {
      path: '/Report/RepeatPurchaseBehavior',
      component: () => import('@!/views/Report/DefaultReport/RepeatPurchaseBehavior.vue'),
      props: route => ({ type: route.query.type, channel: route.query.channel }),
      meta: {
        autoTestPrefix: 'auto_bm160'
      }
    },
    // MarketBasketAnalysis
    {
      path: '/Report/MarketBasketAnalysis',
      component: () => import('@!/views/Report/DefaultReport/MarketBasketAnalysis.vue'),
      props: route => ({ type: route.query.type, channel: route.query.channel }),
      meta: {
        autoTestPrefix: 'auto_bm161'
      }
    },
    // CategoryReport
    {
      path: '/Report/MyReport',
      component: () => import('@!/views/Competitive/ShareOfVoice/index.vue'),
      meta: {
        autoTestPrefix: 'auto_bm156'
      }
    },
    // myreport modules - myreport
    {
      path: '/Reporting/MyReport',
      component: () => import('@!/views/Report/MyReport/MyReport/index.vue'),
      name: 'MyReport',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm157'
      }
    },
    // myreport modules - history
    {
      path: '/Reporting/ReportHistory',
      component: () => import('@!/views/Report/MyReport/ReportHistory/index.vue'),
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm158'
      },
      name: 'ReportHistory'
    },
    // myreport modules - creat
    {
      path: '/Reporting/CreatReport',
      component: () => import('@!/views/Report/MyReport/CreatReport/index.vue'),
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm159'
      }
    }
  ]
} as import('vue-router').RouteLocationPathRaw
