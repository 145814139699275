import { requestOfCallCancel } from '@!/utils/useCancelRequest'
const { VITE_APP_DATA_1P, VITE_APP_DATA_3P } = import.meta.env

/* 获取1p 有SnS数据的Store Id */
export const SnSIdsApi = ({ withCancel = true } = {}): number[] => {
  return requestOfCallCancel({
    baseURL: VITE_APP_DATA_1P,
    url: 'sales/hasSnsDataVendorIds',
    method: 'get',
    isIgnoreRequestRegister: !withCancel
  })
}

/* 获取3p 有SnS数据的Store Id */
export const SnSIdsApi3p = ({ withCancel = true } = {}): string[] => {
  return requestOfCallCancel({
    baseURL: VITE_APP_DATA_3P,
    url: 'sellerSales/hasSnsDataSellerIds',
    method: 'post',
    isIgnoreRequestRegister: !withCancel
  })
}
