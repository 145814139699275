
// 获取 customLayerItem Id
export const getCustomLayerItemId = (customLayerItem) => {
  return customLayerItem.id || customLayerItem.desc
}

// 获取 customLayer 字符 Id
export const getCustomLayerIds = (customLayerList, isSort = true) => {
  const mapIds = customLayerList.map(customLayerItem => {
    return getCustomLayerItemId(customLayerItem)
  })
  if (isSort) {
    return mapIds.sort().join()
  }
  return mapIds.join()
}

// 比对 本地配置 与  远程配置  返回一个对象 包含下一步的配置和是否需要更新远程配置
export const diffCustomLayerConfig = (originCustomLayerConfig, localCustomLayerConfig) => {
  let nextCustomLayerConfig
  // 是否更新远程自定义布局
  let isUpdateOriginCustomLayer = false
  // diff start
  // 1. 长度不一致 以本地配置为主
  if (originCustomLayerConfig.length !== localCustomLayerConfig.length) {
    nextCustomLayerConfig = localCustomLayerConfig
    isUpdateOriginCustomLayer = true
  } else {
    // 2. 长度一致
    const originCustomLayerIds = getCustomLayerIds(originCustomLayerConfig)
    const localCustomLayerIds = getCustomLayerIds(localCustomLayerConfig)
    // 2.1 ids不一致 以本地配置为主
    if (originCustomLayerIds !== localCustomLayerIds) {
      nextCustomLayerConfig = localCustomLayerConfig
      isUpdateOriginCustomLayer = true
    } else {
      // 2.2 ids一致 以远程配置为主
      nextCustomLayerConfig = originCustomLayerConfig
      isUpdateOriginCustomLayer = false
    }
  }
  return {
    nextCustomLayerConfig,
    isUpdateOriginCustomLayer
  }
}
