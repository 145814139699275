import request from "@pacvue/frame/request"
const VITE_APP_BASEURL = import.meta.env.VITE_APP_BASEURL
const VITE_APP_SOV2 = import.meta.env.VITE_APP_SOV2
const VITE_APP_META = import.meta.env.VITE_APP_META
export function GetProfilePageDataTotal(data) {
  return request({
    url: VITE_APP_BASEURL + "Profile/v3/GetProfilePageDataTotal",
    data,
    method: "post"
  })
}

export function GetProfileChartData(data) {
  return request({
    url: VITE_APP_BASEURL + "Profile/v3/GetProfileChartData",
    data,
    method: "post"
  })
}

export function GetProfilePageData(data) {
  return request({
    url: VITE_APP_BASEURL + "Profile/v3/GetProfilePageData",
    data,
    method: "post"
  })
}
//更新Profile名称
export function updateProfileName(data) {
  return request({
    url: VITE_APP_BASEURL + "Profile/v3/UpdataProfileAliasName",
    data,
    method: "post"
  })
}
export function GetProfileBudgetMode(data) {
  return request({
    url: VITE_APP_BASEURL + "Profile/v3/GetProfileBudgetMode",
    data,
    method: "post"
  })
}
export function SetAutomateBudgetControl(data) {
  return request({
    url: VITE_APP_BASEURL + "Budget/v3/SetAutomateBudgetControl",
    data,
    method: "post"
  })
}
export function SetAutomateBudgetPacing(data) {
  return request({
    url: VITE_APP_BASEURL + "Budget/v3/SetAutomateBudgetPacing",
    data,
    method: "post"
  })
}
export function getAllProfiles(data) {
  if (data) {
    data.isAll = false
  } else {
    data = { isAll: false }
  }
  return request({
    url: VITE_APP_META + "apply/getAllProfiles",
    method: "post",
    data
  })
}
