import { getSettings } from '@!/api/Account/settings.js'
import * as storeModule from '@!/store/modules/index.js'
import { setCompareCustom } from '@commerceCommon/utils/storeUtil.js'
import { useUserStore, useCommonStore, useDriverStore, useDriverAlertStore, useAsinDetailStore, useDriverJBPStore, useAppStore } from '@!/store'
import useFrameStore from '@pacvue/frame/frameStore'
import { initWebSocket } from '@!/utils/websocket.js'
import { isEmpty } from '@commerceCommon/utils/common.js'
import { PacvueMessage } from '@pacvue/element-plus'
import { i18n } from '@pacvue/frame/i18nEntry'
import { useAnalyticsPageWithRouterName } from '@pacvue/frame/const'
import useDatePickerOptionsInit from '@!/utils/useDatePickerOptionsInit'
import { watch } from 'vue'
import loginInitMarketStore from '@/utils/loginInitMarketStore'

export const PAGE_TYPE_MAPPER = {
  DisputeOverview: '1p',
  // AlertHistory: '3p',
  Dispute: '1p',
  Payment: '1p',
  Deduction: '1p',
  Claim: '3p',
  ClaimHistory: '3p',
  Repricer: '3p',
  Order: '3p',
  KeywordAnalysis: '1p&3p',
  ShareOfVoice: '1p&3p',
  DefaultReport: '1p&3p',
  MyReport: '1p&3p',
  MailBox: '3p',
  AutomaticRule: '3p',
  TemplateManagement: '3p',
  PacvueTools: '3p',
  MyAccount: '1p&3p',
  UserManage: '1p&3p',
  Setting: '1p&3p',
  VariationTesting: '1p',
  ClaimOverview: '3p',
  APlusContent: undefined,
  addAPlus: undefined
  // Payment: undefined
}

let commonStore = null
export const getPageType = () => {
  if (!commonStore) {
    commonStore = useCommonStore()
  }
  return commonStore.commerceType === 'Vendor' ? { 'page business type': '1p' } : { 'page business type': '3p' }
}

// 初始化页面埋点 - store
export const useAnalyticsPageByStore = ({
  commonStore,
  router
}) => {
  // 监听 store 变化 - 埋点
  return watch(() => commonStore.commerceType,
    () => {
      const routeName = router.currentRoute.value.name
      useAnalyticsPageWithRouterName(routeName, getPageType())
    })
}

// 权限拦截
const usePermissionProxy = async({ to, frameStore }) => {
  return new Promise((resolve, reject) => {
    const toPath = to.path
    const toMeta = to.meta
    const { menuMap } = frameStore
    // 达成进入的条件
    // 1.在 menuMap 里面
    if (menuMap[ toPath ]) {
      return resolve(true)
    }
    // 2.配置忽略权限拦截为 true
    if (toMeta?.ignorePermissionProxy) {
      return resolve(true)
    }
    // 3.关联路由权限已打开
    // 没有配置的情况直接跳转
    const relationPermissionPaths = toMeta?.relationPermissionPaths
    if (isEmpty(relationPermissionPaths)) {
      if (window.permissionObj?.[to.name] === 0 || toMeta?.isNotPublished) {
        resolve(false)
      }
      return resolve(true)
    } else {
      let isNext
      for (let i = 0; i < relationPermissionPaths.length; i++) {
        const relationPermissionPath = relationPermissionPaths[i]
        if (menuMap[ relationPermissionPath ]) {
          isNext = true
          break
        }
      }
      return resolve(isNext)
    }
  })
}

let appStore = null
let frameStore = null
export default {
  async routerInterceptors(to, from, next) {
    if (!appStore) {
      appStore = useAppStore()
    }
    // Asin Time 页面跳转时判断是否是PoP模式，是则设置日期组件自定义比较
    if (to.meta?.showCompareCheck && appStore.isComparePop) {
      setCompareCustom()
    }
    // 路由权限管理
    if (!frameStore) {
      frameStore = useFrameStore()
    }
    const isNext = await usePermissionProxy({ to, frameStore })
    // 不在权限管理直接重定向到根路径
    if (!isNext) {
      // next(getFirstRouter(frameStore.menu))
      next(frameStore.menuMap[window.HomePage] ? window.HomePage : window.firstRouter)
    }
  },
  async loginInterceptors(router) {
    try {
      // 登录前清空store，解决多tab不退出登录store串的问题
      for (const i in storeModule) {
        storeModule[i]()?.$reset()
      }
      const { useCommonStore, useUserStore, useAppStore, useDriverStore, useDriverAlertStore, useDriverJBPStore } = storeModule
      const commonStore = useCommonStore()
      const userStore = useUserStore()
      const appStore = useAppStore()
      const driverStore = useDriverStore()
      const driverAlertStore = useDriverAlertStore()
      const driverJBPStore = useDriverJBPStore()
      await userStore.userAction()
      if (userStore.isDemo) {
        appStore.SET_DATERANGE({
          startDate: '02/01/2023',
          endDate: '02/28/2023'
        })
      }
      useDatePickerOptionsInit(appStore)
      // for (const i in storeModule) {
      //   if (i.startsWith('use') && i.endsWith('Store')) {
      //     const curStore = storeModule[i]()
      //     if (isFunction(curStore.setUserPersist)) {
      //       storeModule[i]()?.setUserPersist()
      //     }
      //   }
      // }
      commonStore.setUserPersist()
      driverStore.setUserPersist()
      driverAlertStore.setUserPersist()
      driverJBPStore.setUserPersist()
      const [permissionList] = await Promise.all([
        commonStore.getUserPermission(),
        loginInitMarketStore()
      ])
      commonStore.mountCustomHeader()
      // WARNING: 上述代码都应该迁移至路由中处理 sso不会走这个逻辑
      // WARNING: 下述代码只是为了暂时满足commerce做的特殊处理，未来依然需要后端的配合改造才能完
      // && window.location.host.includes('commerce')
      // if (userStore.user.availablePlatforms.includes('commerce-walmart') && !userStore.user.availablePlatforms.includes('commerce') && window.location.host.includes('commerce')) {
      //   window.open(`${window.location.protocol}//${window.location.host}/commerce-walmart`, '_self')
      //   return
      // }
      // 登录后建立websocket连接
      if (!window.commerceWebSocket) {
        initWebSocket()
      }
      if (isEmpty(permissionList)) {
        router.push('/Guide')
      } else {
        router.push('/')
      }
    } catch (error) {
      PacvueMessage({
        message: error.message || 'Oops! Something went wrong on the server and we couldn\'t process your request.',
        type: 'error',
        customClass: 'pacvue-message-error'
      })
    }
  },
  userInfoInterceptors: [
    (to, from, next) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          const commonStore = useCommonStore()
          await commonStore.getUserPermission()
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    (to, from, next) => ({
      syncSeq: 1,
      then(onFulfill, onReject) {
        try {
          const commonStore = useCommonStore()
          const driverStore = useDriverStore()
          const driverAlertStore = useDriverAlertStore()
          const driverJBPStore = useDriverJBPStore()
          commonStore.setUserPersist()
          driverStore.setUserPersist()
          driverAlertStore.setUserPersist()
          driverJBPStore.setUserPersist()
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    })
  ],
  settingInterceptors: [
    (context, to, from) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          let i8 = 'EN'
          let settingConfig = null
          if (sessionStorage.getItem('setting')) {
            const setting = JSON.parse(sessionStorage.getItem('setting'))
            i8 = setting.language
            settingConfig = setting
          } else {
            const setting = await getSettings()
            settingConfig = setting
            i8 = setting.language
          }
          if (i8 !== localStorage.getItem('locale')) {
            localStorage.setItem('locale', i8)
            // 偏好语言与当前环境不同, 则需要重刷页面, 拿对应语言最新的语言包
            const url = new URL(window.location.href)
            const params = new URLSearchParams(url.search)
            const pathSlices = url.pathname.split('/')
            if (from.path.includes('login') || from.path === '/Auth') {
              window.open(`${url.origin}${pathSlices.slice(0, pathSlices.length - 1).join('/')}${to.path}?${params.toString()}`, '_self')
            } else {
              window.location.reload()
            }
          }
          if (i18n.mode === 'legacy') {
            i18n.global.locale = i8
          } else {
            i18n.global.locale.value = i8
          }
          let elementLocale = 'en'
          if (localStorage.getItem('locale') === 'ZH') {
            elementLocale = 'zh-cn'
          } else if (localStorage.getItem('locale') === 'JA') {
            elementLocale = 'ja'
          }
          if (window.__inject_pacvue_element_changeLocale__) {
            window.__inject_pacvue_element_changeLocale__({
              option: { locale: { name: elementLocale }}
            })
          }
          if (settingConfig) {
            context.SET_USERSETTINGS(settingConfig)
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    (context, to, from) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          const commonStore = useCommonStore()
          await commonStore.getVendorOrSellerIsSnsData({ withCancel: false })
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    (context, to, from) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          const commonStore = useCommonStore()
          await commonStore.getVendorOrSellerSnsIds({ withCancel: false })
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    (context, to, from) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          const commonStore = useCommonStore()
          await commonStore.getManualCasePermission()
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    (context, to, from) => ({
      syncSeq: 1,
      then(onFulfill, onReject) {
        try {
          const commonStore = useCommonStore()
          const asinDetailStore = useAsinDetailStore()
          commonStore.getManagedByPacvueImp({ withCancel: false })
          // 同步 asinDetail openType by userStore
          asinDetailStore.setOpenAsinDetailTypeByArrEnum(context.userSettings.asinStatusDetail, false)
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    })
  ]
}
