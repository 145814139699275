import Main from '@pacvue/frame/layout/Main.vue'
export default [
  {
    path: '/Catalog',
    component: Main,
    meta: {
      auth: true,
      id: 2
    },
    sort: 700,
    redirect: '/Catalog/Catalog',
    children: [
      {
        path: '/Catalog/Catalog',
        component: () => import('@!/views/Catalog/Catalog/index.vue'),
        name: 'CatalogMain',
        meta: {
          isShowTime: false,
          showCompareCheck: false,
          autoTestPrefix: 'auto_bm136'
        }
      },
      {
        path: '/Catalog/PIM',
        component: () => import('@!/views/Catalog/PIM/index.vue'),
        name: 'PIM',
        meta: {
          isShowTime: false,
          showCompareCheck: false,
          autoTestPrefix: 'auto_bm138',
          // 暂时配置一下这个，隐藏一下
          isNotPublished: true
        }
      },
      {
        path: '/Catalog/PIM/detail',
        component: () => import('@!/views/Catalog/PIM/AddProduct/index.vue'),
        name: 'AddProduct',
        meta: {
          isShowTime: false,
          showCompareCheck: false,
          autoTestPrefix: 'auto_bm139'
        }
      },
      {
        path: '/Catalog/APlusContent',
        component: () => import('@!/views/Catalog/APlusContent/index.vue'),
        name: 'APlusContent',
        meta: {
          isShowTime: false,
          showCompareCheck: false,
          autoTestPrefix: 'auto_bm137'
        }
      },
      {
        path: '/Catalog/APlusContent/detail',
        component: () => import('@!/views/Catalog/APlusContent/addAPlus.vue'),
        name: 'addAPlus',
        meta: {
          isShowTime: false,
          showCompareCheck: false,
          autoTestPrefix: 'auto_bm140'
        }
      },
      {
        path: '/Catalog/UploadQueue',
        component: () => import('@!/views/Catalog/UploadQueue/index.vue'),
        name: 'UploadQueue',
        meta: {
          isShowTime: false,
          showCompareCheck: false,
          autoTestPrefix: 'auto_bm142'
        }
      },
      { // 1p
        path: '/Catalog/UpdateCatalog',
        component: () => import('@!/views/Catalog/UpdateCatalog/index.vue'),
        name: 'UpdateCatalog',
        meta: {
          isShowTime: true,
          showCompareCheck: false,
          autoTestPrefix: 'auto_bm141'
        }
      },
      { // 3p
        path: '/Catalog/UpdateLog',
        component: () => import('@!/views/Catalog/UpdateCatalog/SellerLog/index.vue'),
        name: 'UpdateLog',
        meta: {
          isShowTime: false,
          showCompareCheck: false,
          autoTestPrefix: 'auto_bm143'
        }
      },
      { // 1p UpdateCatalog
        path: '/Catalog/BulkLog',
        component: () => import('@!/views/Catalog/BulkLog/UpdateCatalog.vue'),
        name: 'BulkLog',
        meta: {
          isShowTime: true,
          showCompareCheck: false,
          // 埋点需要的名字
          analyticsName: 'Bulk Operation Log',
          autoTestPrefix: 'auto_bm144'
        }
      },
      { // 1p VariationTesting
        path: '/Catalog/Variationtesting',
        component: () => import('@!/views/Catalog/VariationTesting/index.vue'),
        name: 'VariationTesting',
        meta: {
          isShowTime: true,
          autoTestPrefix: 'auto_bm146'
        }
      },
      {
        path: '/Catalog/Catalog/EditListingDetail/detail',
        component: () => import('@!/views/Catalog/EditListingDetail/index.vue'),
        name: 'EditListingDetail',
        meta: {
          isShowTime: false,
          autoTestPrefix: 'auto_bm1410'
        }
      }
    ]
  }
]
