import { useCsStore } from '@!/store'
import Main from '@pacvue/frame/layout/Main.vue'
export default {
  path: '/CustomerService',
  component: Main,
  meta: {
    auth: true,
    id: 666
  },
  sort: 0,
  redirect: '/CustomerService/MailBox',
  children: [
    {
      path: '/CustomerService/MailBox',
      component: () => import('@!/views/CustomerService/Mailbox/index.vue'),
      async beforeEnter(to, from, next) {
        const csStore = useCsStore()
        await csStore.getMailboxFolder({
          countryCodes: [],
          sellerIds: []
        }, true)
        next()
      },
      name: 'MailBox',
      meta: {
        autoTestPrefix: 'auto_bm160'
      }
    },
    {
      path: '/CustomerService/AutomaticRule',
      component: () => import('@!/views/CustomerService/AutomaticRule/index.vue'),
      name: 'AutomaticRule',
      meta: {
        autoTestPrefix: 'auto_bm161'
      }
    },
    {
      path: '/CustomerService/TemplateManagement',
      component: () => import('@!/views/CustomerService/TemplateManagement/index.vue'),
      name: 'TemplateManagement',
      meta: {
        autoTestPrefix: 'auto_bm162'
      }
    }
  ]
} as import('vue-router').RouteLocationPathRaw
