import { getUUID, isEmpty } from '@commerceCommon/utils/common'

export const WEB_CLIENT_ID_KEY = 'web_client_id'

export default function() {
  let webClientId = localStorage.getItem(WEB_CLIENT_ID_KEY)
  if (isEmpty(webClientId)) {
    webClientId = getUUID()
    localStorage.setItem(WEB_CLIENT_ID_KEY, webClientId)
  }
  return webClientId
}
