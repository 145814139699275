import Main from '@pacvue/frame/layout/Main.vue'
export default [
  {
    path: '/ShareLink',
    component: Main,
    meta: {
      auth: false,
      id: 3
    },
    sort: 0,
    redirect: '/ShareLink/',
    children: [
      {
        path: '/ShareLink/:type',
        name: 'ShareLink',
        component: () => import('@!/views/ShareLink/index.vue'),
        meta: {
          isShowTime: false,
          showCompareCheck: false
        }
      },
      {
        path: '/ShareLink/ShareLinkConfig/:query',
        name: 'ShareLinkConfig',
        component: () => import('@!/views/ShareLink/ShareLinkConfig/index.vue'),
        meta: {
          isShowTime: false,
          showCompareCheck: false
        }
      }
    ]
  }
]
