import { requestOfCallCancel } from '@!/utils/useCancelRequest'

// 根据key 获取用户自定义的值
export const getUserCustomJson = (bindKey) => {
  return requestOfCallCancel({
    url: '/columns/json',
    method: 'post',
    data: {
      cookieKey: bindKey,
      cookieJson: ''
    }
  })
}

// 根据key value 更新用户自定义的值
export const updateUserCustomJson = (bindKey, bindVal) => {
  return requestOfCallCancel({
    url: '/columns/json',
    method: 'post',
    data: {
      cookieKey: bindKey,
      cookieJson: bindVal
    }
  })
}
