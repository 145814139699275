import { attempt } from 'lodash-es'

const isWatchAction = (watchFields, name) => {
  if (watchFields && watchFields.length === 0) {
    return ''
  }
  const lowerName = (name || '').toLowerCase()
  let watchName = ''

  if (lowerName.indexOf('set_') === 0) {
    watchName = lowerName.slice(4)
  } else if (lowerName.includes('set') && !lowerName.includes('_')) {
    watchName = lowerName.slice(3)
  }
  const val = watchFields.find((i) => {
    return i.toLowerCase() === watchName
  })
  return val || ''
}

export const userStorePersistPlugin = ({
  PiniaPersistList
}) => {
  return ({ store, options }) => {
    const defaultPersistOptions = {
      // 是否独立存储
      isModuleStorage: false
    }
    const persist = options.persist || {}
    const platformName = store.platformName
    const moduleName = store.$id
    // 创建 store key
    const createStorageKey = ({ platformName = '', fieldName = '' }) => {
      const mergedPersistOptions = { ...defaultPersistOptions, ...persist }
      const key_storage = PiniaPersistList.includes(fieldName) ? 'storage' : ''
      const key_userId = localStorage.getItem('uid')
      const key_platformName = persist.isCommon ? '' : platformName
      const key_moduleName = mergedPersistOptions.isModuleStorage ? moduleName : ''
      const storeKey = attempt(() => {
        const splitKeyList = [
          key_storage,
          key_userId,
          key_platformName,
          key_moduleName,
          fieldName
        ]
        const splitKeyStr = splitKeyList.filter(key => key).join('_')
        return splitKeyStr
      })
      return storeKey
    }

    let watchFields = []
    if (persist.enabled) {
      // 开启持久化
      watchFields = persist.watchFields || []
      watchFields.forEach((fieldName) => {
        const storeKey = createStorageKey({ platformName, fieldName })
        const storeStr = localStorage.getItem(storeKey)
        if (storeStr) {
          store[fieldName] = JSON.parse(storeStr)
        }
      })
    }
    store.$subscribe((mutation, state) => { })
    store.$onAction(
      ({
        name, // action 的名字
        store, // store 实例
        args, // 调用这个 action 的参数
        after, // 在这个 action 执行完毕之后，执行这个函数
        onError // 在这个 action 抛出异常的时候，执行这个函数
      }) => {
        after((result) => {
          // 在存储变化的时候执行
          const userId = localStorage.getItem('uid')
          const watchName = isWatchAction(watchFields, name)
          if (watchName && userId) {
            // 添加这个key，防止被pacvue模块退出时清除
            const storeKey = createStorageKey({ platformName: store.platformName, fieldName: watchName })
            localStorage.setItem(storeKey, JSON.stringify(store[watchName]))
          }
        })
        onError((err) => {
          console.warn('store', name, err)
        })
      }
    )
  }
}
