import { useAppStore, useUserStore } from '@#/store'
import { computed } from 'vue'
import { dayjs } from '@pacvue/element-plus'
import { isEmpty } from '@commerceCommon/utils/common'

export const dateRange = computed(() => {
  const appStore = useAppStore()
  const { startDate, endDate } = appStore.dateRange
  return {
    startDate: dayjs(startDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
    endDate: dayjs(endDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
  }
})

export const compareDateRange = computed(() => {
  const appStore = useAppStore()
  const { start, end } = appStore.compareTime
  return {
    startDate: dayjs(start, 'MM/DD/YYYY').format('YYYY-MM-DD'),
    endDate: dayjs(end, 'MM/DD/YYYY').format('YYYY-MM-DD')
  }
})

export const compareSetting = computed(() => {
  const appStore = useAppStore()
  const isShowCompare = appStore.isCompare
  const isOnlyCompareSummaryData = appStore.compareSummary
  const isCompareCustom = appStore.isCompareCustom
  const isComparePop = appStore.isComparePop
  const compareType = isCompareCustom ? 'Custom' : isComparePop ? 'PoP' : 'YoY'
  return {
    isShowCompare,
    isCompareCustom,
    isOnlyCompareSummaryData,
    compareType
  }
})

export const userSetting = computed(() => {
  const userStore = useUserStore()
  return {
    defaultCountryCode: userStore.defaultCountryCode,
    defaultMoneySymbol: userStore.defaultMoneySymbol
  }
})

export const latestDateMode = computed(() => {
  const appStore = useAppStore()
  let latestDateMode = appStore.latestDateMode
  if (!latestDateMode) {
    return 'Custom_Range'
  }
  latestDateMode = latestDateMode.replace('(Exclude latest 2 days)', 'Ex')
  return latestDateMode.replaceAll(' ', '_')
})

/**
 * 设置延迟日期列表，示例['2024-05-21', '2024-05-23', '2024-05-24']
 * @param dateList
 */
export const setDelayDateList = (dateList) => {
  const appStore = useAppStore()
  const transformedDate = (date) => dayjs(date, 'YYYY-MM-DD').format('MM/DD/YYYY')
  const delayDateText = isEmpty(dateList)
    ? ''
    : dateList.length === 1
      ? transformedDate(dateList[0])
      : [dateList[0], dateList[dateList.length - 1]].map(transformedDate).join('~')
  appStore.SET_DATEPICKEROPTIONS({
    ...appStore.datepickerOptions,
    delayDateText: delayDateText ? `${$t('common.dataDelay')}: ${delayDateText}` : ''
  })
}


/**
 * 设置是否显示PoP
 * @param bShowPop
 */
export const setShowCustomPop = (bShowPop = false) => {
  const popConfig = { isComparePop: bShowPop, showComparePop: bShowPop }
  const appStore = useAppStore()
  appStore.SET_DATEPICKEROPTIONS({
    ...appStore.datepickerOptions,
    ...popConfig
  })
}

/**
 * 设置默认选择custom
 */
export const setCompareCustom = () => {
  const appStore = useAppStore()
  appStore.SET_ISCOMPARECUSTOM(true)
  appStore.SET_ISCOMPAREPOP(false)
  appStore.SET_ISCOMPAREYOY(false)
  const latestDateMode = appStore.latestDateMode
  let start = null
  let end = null
  const { startDate, endDate } = appStore.dateRange
  if (latestDateMode === 'This Week') {
    start = new Date(dayjs().subtract(1, 'weeks').startOf('week').format())
  } else if (latestDateMode === 'This Month' || latestDateMode === 'Last Month') {
    const thisMonth = new Date(startDate).getMonth()
    const thisYear = new Date(startDate).getFullYear()
    start = new Date(thisYear, thisMonth - 1, 1, 0, 0, 0)
  } else if (latestDateMode === 'Year to Date' || latestDateMode === 'Last Year') {
    const thisYear = new Date(startDate).getFullYear()
    start = new Date(thisYear - 1, 0, 1, 0, 0, 0)
  } else if (latestDateMode === 'Last 12 months') {
    start = new Date(dayjs(startDate).subtract(1, 'year'))
  } else {
    const day = dayjs(new Date(endDate)).diff(dayjs(new Date(startDate)), 'day')
    const startday = dayjs(new Date(startDate)).subtract(day + 1, 'day')
    start = startday.toDate()
  }
  end = dayjs(new Date(startDate)).subtract(1, 'day').toDate()
  appStore.SET_COMPARETIME({
    start: dayjs(start).format('MM/DD/YYYY'),
    end: dayjs(end).format('MM/DD/YYYY')
  })
}

/**
 * 设置Apply按钮的前置拦截器
 * @param fn
 */
export const setBeforeApplyInterceptor = (fn) => {
  const appStore = useAppStore()
  appStore.SET_DATEPICKEROPTIONS({
    ...appStore.datepickerOptions,
    beforeApply: fn
  })
}

export default {
  dateRange,
  compareDateRange,
  latestDateMode,
  compareSetting,
  userSetting,
  setDelayDateList,
  setShowCustomPop,
  setCompareCustom,
  setBeforeApplyInterceptor
}
