import Main from '@pacvue/frame/layout/Main.vue'
export default {
  path: '/Overview',
  component: Main,
  meta: {
    auth: false,
    id: 2
  },
  sort: 200,
  redirect: '/Overview/InventoryOverview',
  children: [
    {
      path: '/Overview/SalesOverview',
      name: 'SalesOverview',
      meta: {
        isShowTime: true,
        showCompareCheck: false
      },
      redirect: '/Overview/BrandSalesOverview',
      children: [
        {
          path: '/Overview/BrandSalesOverview',
          component: () => import('@!/views/Overview/Sales/Brand/index.vue'),
          name: 'BrandSalesOverview',
          meta: {
            isShowTime: true,
            showCompareCheck: true,
            autoTestPrefix: 'auto_bm105'
          }
        },
        {
          path: '/Overview/CategorySalesOverview',
          component: () => import('@!/views/Overview/Sales/Category/index.vue'),
          name: 'CategorySalesOverview',
          meta: {
            isShowTime: true,
            showCompareCheck: true,
            autoTestPrefix: 'auto_bm106'
          }
        }
      ]
    },
    {
      path: '/Overview/InventoryOverview',
      component: () => import('@!/views/Inventory/Overview/index.vue'),
      name: 'InventoryOverview',
      meta: {
        isShowTime: true,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm114'
      }
    },
    {
      path: '/Overview/ProfitabilityOverview',
      component: () => import('@!/views/Profitability/Overview/index.vue'),
      name: 'ProfitabilityOverview',
      meta: {
        isShowTime: true,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm117'
      }
    },
    {
      path: '/Overview/AlertOverview',
      component: () => import('@!/views/Alert/AlertOverview/index.vue'),
      name: 'AlertOverview',
      meta: {
        isShowTime: true,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm102'
      }
    },
    {
      path: '/Overview/BuyboxOverview',
      component: () => import('@!/views/Overview/BuyboxOverview/index.vue'),
      name: 'BuyboxOverview',
      meta: {
        isShowTime: true,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm127'
      }
    },
    {
      path: '/Overview/MerchandisingOverview',
      component: () => import('@!/views/Overview/MerchandisingOverview/index.vue'),
      name: 'MerchandisingOverview',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm108'
      }
    },
    {
      path: '/Overview/OptimizationOverview',
      component: () => import('@!/views/Overview/OptimizationOverview/index.vue'),
      name: 'OptimizationOverview',
      meta: {
        isShowTime: false,
        autoTestPrefix: 'auto_bm126'
      }
    },
    {
      path: '/Overview/DisputeOverview',
      component: () => import('@!/views/Overview/DisputeOverviewNew/index.vue'),
      name: 'DisputeOverview',
      meta: {
        isShowTime: true,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm119'
      }
    },
    {
      path: '/Overview/ClaimOverview',
      component: () => import('@!/views/Overview/ClaimOverviewNew/index.vue'),
      name: 'ClaimOverview',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm120'
      }
    },
    {
      path: '/Overview/SnsOverview',
      component: () => import('@!/views/Overview/SnsOverview/index.vue'),
      name: 'SNSOverview',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm107'
      }
    }
  ]
}
