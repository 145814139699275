import Main from '@pacvue/frame/layout/Main.vue'
export default {
  path: '/DownloadCenter',
  component: Main,
  redirect: '/DownloadCenter',
  name: 'DownloadCenter',
  meta: {
    title: 'DownloadCenter'
  },
  sort: 0,
  hidden: true,
  children: [
    {
      path: '/DownloadCenter',
      name: 'DownloadCenter',
      component: () => import('@!/views/DownloadCenter/index.vue'),
      meta: {
        isShowTime: false,
        autoTestPrefix: 'auto_bm164'
      }
    }
  ]
}
