import { dayjs } from '@pacvue/element-plus'

export default class YoyWeek {
  constructor() {
    // 获取当前周数
    this.thisWeekNumber = dayjs().week()
    // 获取上周数
    const lastWeekNumber = this.thisWeekNumber - 1
    // 获取选中范围内的结束周数
    this.endWeekNumber = lastWeekNumber === 53 ? 52 : lastWeekNumber
    // 获取结束周数对应的上一年的年
    this.yoyYear = lastWeekNumber === 53 ? dayjs().year() - 2 : dayjs().year() - 1
    // 获取结束周数对应的上一年的周
    this.yoyLastWeek = dayjs(`${this.yoyYear}`, 'YYYY').week(this.endWeekNumber)
  }

  /**
   * 获取当前年份的周数
   * @returns {*}
   */
  getYearWeek() {
    return dayjs(`${dayjs().year()}-12-28`, 'YYYY-MM-DD').week()
  }

  getStartYoyWeek(weekRange) {
    const lastWeekNumber = this.endWeekNumber - weekRange + 1
    return dayjs(`${this.yoyYear}`, 'YYYY').week(lastWeekNumber)
  }

  getWeekRange(startWeek = this.yoyLastWeek, endWeek = this.yoyLastWeek) {
    return [startWeek.startOf('week'), endWeek.endOf('week')]
  }

  getLastWeek() {
    return this.getWeekRange()
  }

  getThisWeek() {
    const endWeekNumber = this.thisWeekNumber === 53 ? 52 : this.thisWeekNumber
    const yoyYear = dayjs().year() - 1
    const yoyLastWeek = dayjs(`${yoyYear}`, 'YYYY').week(endWeekNumber)
    return this.getWeekRange(yoyLastWeek, yoyLastWeek)
  }

  getLast2Weeks() {
    return this.getWeekRange(this.getStartYoyWeek(2))
  }

  getLast4Weeks() {
    return this.getWeekRange(this.getStartYoyWeek(4))
  }

  getLast13Weeks() {
    return this.getWeekRange(this.getStartYoyWeek(13))
  }

  getLast26Weeks() {
    return this.getWeekRange(this.getStartYoyWeek(26))
  }

  getLast52Weeks() {
    return this.getWeekRange(this.getStartYoyWeek(52))
  }

  getYoyWeekConfig() {
    const weekList = ['Last Week', 'This Week', 'Last 2 Weeks', 'Last 4 Weeks', 'Last 13 Weeks', 'Last 26 Weeks', 'Last 52 Weeks']
    return weekList.reduce((previousValue, currentValue) => {
      previousValue[currentValue] = this[`get${currentValue.replace(/\s/g, '')}`]()
      return previousValue
    }, {})
  }
}
