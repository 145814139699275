import { uniq } from 'lodash-es'
import * as storeModule from '../index'
import { getUseStoreKey, strCapitalize } from '../util'

export default {
  // market 下拉数据
  marketDropData() {
    return this.getMarketDropDataOfMarketStore()
  },
  marketStoreValue() {
    return this.marketStoreValueMap[this.commerceType.toLowerCase()]
  },
  brandCategoryValue() {
    return this.brandCategoryValueMap[this.commerceType.toLowerCase()]
  },
  originBrandCategoryValue() {
    return this.originBrandCategoryValueMap[this.commerceType.toLowerCase()]
  },
  originBrandCategoryValueByVendor() {
    return this.originBrandCategoryValueMap.vendor
  },
  customBrandCategoryValue() {
    return this.customBrandCategoryValueMap[this.commerceType.toLowerCase()]
  },
  distributorAndSellingValue() {
    return this.distributorAndSellingMap || { distributor: 'Manufacturing', selling: 'Retail' }
  },
  repSuggestionTypeValue() {
    return this.repSuggestionType
  },
  tagValueByVendor() {
    return this.tagValueMap.vendor
  },
  tagValue() {
    return this.tagValueMap[this.commerceType.toLowerCase()]
  },
  problemTypeValue() {
    return this.problemTypeValueMap[this.commerceType.toLowerCase()]
  },
  // marketStore 对应平台的值
  marketStoreValueBySeller() {
    return this.marketStoreValueMap.seller
  },
  marketStoreValueByVendor() {
    return this.marketStoreValueMap.vendor
  },
  // 获取Vendor Seller缓存选中值的并集
  marketStoreValueMixin() {
    const { vendor, seller } = this.marketStoreValueMap
    return {
      market: uniq([...vendor.market ?? [], ...seller.market ?? []]),
      store: uniq([...vendor.store ?? [], ...seller.store ?? []])
    }
  },
  // 判断sns数据
  isSnsVendor() {
    return this.hasSnsData.vendor
  },
  isSnsSeller() {
    return this.hasSnsData.seller
  },
  getManagedByPacvue() {
    return this.managedByPacvue
  },
  // 判断是否1p Alert Overview跳转history
  isOverviewJumpHistoryByVendor() {
    return this.overviewJumpHistoryMap.vendor
  },
  // 判断sns数据
  SnsVendorIds() {
    return this.SnsIds.vendor
  },
  SnsSellerIds() {
    return this.SnsIds.seller
  },
  // 判断平台类型
  isSeller() {
    return this.isCommerceType('seller')
  },
  isVendor() {
    return this.isCommerceType('vendor')
  },
  platformName(state) {
    const useStoreKey = getUseStoreKey(state.platform)
    if (storeModule[useStoreKey]) {
      const store = storeModule[useStoreKey]()
      return store.platformName
    }
    return state.platform
  },
  curStore(state) {
    const storeName = `use${strCapitalize(state.platform)}Store`
    return storeModule[storeName]()
  },
  selectedProfiles(state) {
    return this.curStore.selectedProfiles || []
  },
  selectedCamTags(state) {
    return this.curStore.selectedCamTags || []
  },
  selectedPortfolios(state) {
    return this.curStore.selectedPortfolios || []
  },
  selectedCampaigns(state) {
    return this.curStore.selectedCampaigns || []
  },
  selectedAdgroups(state) {
    return this.curStore.selectedAdgroups || []
  },
  adProfileList() {
    return this.curStore.profileList || []
  },
  adProfileIds() {
    return this.adProfileList.map((item) => {
      return item.id
    })
  },
  adAllProfileList() {
    return this.curStore.allProfileList || []
  },
  adAllProfileIds() {
    return this.adAllProfileList.map((item) => {
      return item.id
    })
  },
  salePanelValue() {
    return this.salePanelMap[this.commerceType.toLowerCase()]
  },
  realTimeSalePanelValue() {
    return this.realTimeSalePanelMap[this.commerceType.toLowerCase()]
  },
  getIsAllAsyncDataComplete() {
    return this.isAllAsyncDataComplete
  }
}
