import Main from '@pacvue/frame/layout/Main.vue'

export default {
  path: '/ProfitRecoup',
  component: Main,
  meta: {
    auth: true,
    id: 2
  },
  sort: 400,
  redirect: '/ProfitRecoup/Claim',
  children: [
    {
      path: '/ProfitRecoup/Claim',
      component: () => import('@!/views/ProfitRecoup/NewClaim/index.vue'),
      name: 'Claim',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm124'
      }
    },
    {
      path: '/Alert/CaseHistory',
      component: () => import('@!/views/ProfitRecoup/ClaimCases/index.vue'),
      name: 'ClaimHistory',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm125'
      }
    },
    {
      path: '/ProfitRecoup/Deduction',
      name: 'DisputeGroup',
      meta: {
        isShowTime: false,
        showCompareCheck: false
      },
      redirect: '/ProfitRecoup/Deduction',
      children: [
        {
          path: '/ProfitRecoup/Deduction',
          component: () => import('@!/views/ProfitRecoup/NewDispute/index.vue'),
          name: 'Deduction',
          meta: {
            isShowTime: false,
            showCompareCheck: false,
            autoTestPrefix: 'auto_bm121'
          }
        },
        {
          path: '/ProfitRecoup/Dispute',
          component: () => import('@!/views/ProfitRecoup/Dispute/index.vue'),
          name: 'Dispute',
          meta: {
            isShowTime: false,
            showCompareCheck: false,
            autoTestPrefix: 'auto_bm122'
          }
        },
        {
          path: '/ProfitRecoup/DisputeBilling',
          component: () => import('@!/views/ProfitRecoup/Dispute/Billing/index.vue'),
          name: 'DisputeBilling',
          meta: {
            isShowTime: false,
            showCompareCheck: false
          }
        },
        {
          path: '/ProfitRecoup/Payment',
          component: () => import('@!/views/ProfitRecoup/Payment/index.vue'),
          name: 'Payment',
          meta: {
            isShowTime: false,
            showCompareCheck: false,
            autoTestPrefix: 'auto_bm123'
          }
        }
      ]
    }
  ]
}
