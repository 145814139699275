import Main from '@pacvue/frame/layout/Main.vue'
export default {
  path: '/Account',
  component: Main,
  meta: {
    auth: true,
    id: 2
  },
  sort: 0,
  redirect: '/Account/MyAccount',
  children: [
    {
      path: '/Account/MyAccount',
      component: () => import('@!/views/Account/MyAccount.vue'),
      name: 'MyAccount',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm165'
      }
    },
    {
      path: '/Binding',
      component: () => import('@!/views/Account/Binding.vue'),
      name: 'Binding',
      meta: {
        noBreadcrumb: true
      }
    },
    // {
    //   path: '/Account/UserManage',
    //   component: () => import('@!/views/Account/UserManage.vue'),
    //   name: 'UserManage',
    //   meta: {
    //     isShowTime: false,
    //     showCompareCheck: false
    //   }
    // },
    {
      path: '/Account/Setting',
      component: () => import('@!/views/Account/Setting.vue'),
      name: 'Setting',
      meta: {
        isShowTime: false,
        showCompareCheck: false,
        autoTestPrefix: 'auto_bm166'
      }
    },
    {
      path: '/Guide',
      component: () => import('@!/views/Account/Guide.vue'),
      name: 'Guide',
      meta: {
        noBreadcrumb: true
      }
    },
    {
      path: '/Account/RRM',
      component: () => import('@!/views/RRMIntegration/index.vue'),
      name: 'RRM',
      meta: {}
    },
    {
      path: '/RRM/Onboarding',
      component: () => import('@!/views/RRMIntegration/Instruction/Onboarding/index.vue'),
      name: 'Onboarding',
      meta: {}
    }
  ]
}
