import { $http3p } from '../http'
/**
 * @description 查询规则列表
 * @param { Array<String> } countryCodes
 * @param { Number } pageNum
 * @param { Number } pageSize
 * @param { Array<String> } sellerIds
 * @param { String } sort
 * @param { String } sortField
 */
export const queryRules = (data) => {
  return $http3p({
    url: 'mailbox/autoRule/list',
    method: 'post',
    data
  })
}
/**
 * @description 查询规则详情
 * @param { Array<String> } countryCodes
 * @param { Array<String> } sellerIds
 * @param { String } ruleId
 */
export const queryRuleDetail = (data) => {
  return $http3p({
    url: 'mailbox/autoRule/detail',
    method: 'post',
    data
  })
}
/**
 * @description 修改规则开启状态
 * @param { Array<String> } countryCodes
 * @param { Array<String> } sellerIds
 * @param { Boolean } ruleStatus
 * @param { String } ruleId
 */
export const updateRuleStatus = (data) => {
  return $http3p({
    url: 'mailbox/autoRule/updateStatus',
    method: 'post',
    data
  })
}
/**
 * @description 删除规则
 * @param { Array<String> } countryCodes
 * @param { Array<String> } sellerIds
 * @param { String } ruleId
 */
export const deleteRule = (data) => {
  return $http3p({
    url: 'mailbox/autoRule/delete',
    method: 'post',
    data
  })
}
/**
 * @description 查询数据来源
 * @param { Array<String> } countryCodes
 * @param { Array<String> } sellerIds
 */
export const queryAddressList = (data) => {
  return $http3p({
    url: 'mailbox/address/list',
    method: 'post',
    data
  })
}
/**
 * @description 创建规则
 */
export const createRule = (data) => {
  return $http3p({
    url: 'mailbox/autoRule/create',
    method: 'post',
    data
  })
}
/**
 * @description 更新规则
 */
export const updateRule = (data) => {
  return $http3p({
    url: 'mailbox/autoRule/update',
    method: 'post',
    data
  })
}

/**
 * @description 模板列表
 */
export const queryTemplates = () => {
  return $http3p({
    url: 'mailbox/template/tree',
    method: 'post'
  })
}
/**
 * @description 查询分区
 */
export const getTimeZone = () => {
  return $http3p({
    url: 'mailbox/timeZoneInfo',
    method: 'get'
  })
}

