export const RowHeight = 72
export const RowHeightBySmall = 52
// drawer 弹框宽度
export const DrawerSize = '70%'

// drawer 弹框默认配置
export const DrawerDefaultProps = {
  direction: 'rtl',
  size: DrawerSize,
  'append-to-body': true,
  'close-on-click-modal': false,
  'close-on-press-escape': false
}

export const DrawerNoModalProps = {
  ...DrawerDefaultProps,
  size: '100%',
  modal: false
}

// dialog 弹框默认配置
export const DialogDefaultProps = {
  'append-to-body': true,
  'close-on-click-modal': false,
  'close-on-press-escape': false
}

// 默认tag
export const DefaultTagData = () => {
  return [{ tagId: '-10', tagName: `[${$t('CommerceCommon.Filter.NoTagProduct')}]` }]
}
export const DefaultCustomColumnTeleport = 'common-custom-column-teleport'

// filter中Product搜索最大100行,超过部分会截断
export const ProductSearchMaxLine = 100
