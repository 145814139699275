export default function() {
  return {
    shareStates: {},
    /* 用于存创建ShareLink时候的数据
      shareId => shareLinkData
      querydata在这个对象里
    */
    shareLinkData: {},
    /* 用于存Share的全局下拉组件的Option
      shareId => shareDropData
        Market(使用默认的右侧面包屑显示MarketStore的话已经处理)
        ProductTag
        Category
        Brand
    */
    shareDropData: {},
    // 默认值为 true 因为刷新页面会重新拉去数据
    isAllAsyncDataComplete: true
  }
}
