import { useCommonStore } from '@!/store' // 从store模块导入useCommonStore方法

export default async function() {
  const commonStore = useCommonStore() // 使用useCommonStore钩子获取commonStore实例
  const types = ['Vendor', 'Seller'] // 定义需要处理的类型数组
  // 为每个类型创建一个包含类型标记和可能为null的promise的对象
  const asyncList = types.map(type => {
    const { market, store } = commonStore[`marketStoreValueBy${type}`] // 根据类型获取market和store的值
    // 如果market和store都为空，则准备发起异步请求，否则promise为null
    const promise = market.length === 0 && store.length === 0 ? commonStore.getMarketStorePro(type) : null
    return { type, promise } // 返回包含类型和promise的对象
  })

  // 使用Promise.all等待所有promise完成，过滤掉了null的promise
  const results = await Promise.all(asyncList.map(item => item.promise))

  // 遍历结果数组
  results.forEach((result, index) => {
    const type = asyncList[index].type // 获取当前结果对应的类型
    if (result?.length) { // 如果结果非空
      const { market, storeList } = result[0] // 从结果中解构出market和storeList
      // 根据storeList的长度构建marketStore对象
      const marketStore = {
        market: [market], // 市场ID作为数组的第一个元素
        store: storeList.length > 5 ? [storeList[0].id] : storeList.map(item => item.id) // 根据数量决定选取哪些
      }
      commonStore.setMarketStoreValueMap(marketStore, type) // 更新commonStore中的marketStore值
    }
  })
}
