/* 此文件为垫片方法, 用于兼容一些低版本浏览器不支持的方法, 降低datadog的错误率
 * 用法：在项目的入口文件中直接引入
 * import '@commerceCommon/utils/shimMethods'
*/

/* title: Array.prototype.at
 * 标准: https://tc39.es/ecma262/multipage/indexed-collections.html#sec-array.prototype.at
 */
function atShim(index) {
  if (Number.isNaN(Number(index))) {
    index = 0
  }
  index = Math.trunc(index) || 0
  if (index < 0) { index += this.length }
  if (index < 0 || index >= this.length) { return undefined }
  return this[index]
}
if (!Array.prototype.at) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.at = atShim
}

// test: test Array.prototype.at
declare global {
  interface Array<T> {
    atTest(index: number): T | undefined;
  }
}
if (import.meta.vitest) {
  const { describe, expect, it, test } = import.meta.vitest
  // eslint-disable-next-line no-extend-native
  Array.prototype.atTest = atShim
  describe('Array.prototype.at', () => {
    it('should return the correct value', () => {
      const arr = [1, 2, 3, 4, 5]
      expect(arr.atTest(0)).toBe(1)
      expect(arr.atTest(2)).toBe(3)
      expect(arr.atTest(-1)).toBe(5)
      expect(arr.atTest(-3)).toBe(3)
      expect(arr.atTest(5)).toBe(undefined)
      expect(arr.atTest(-6)).toBe(undefined)
    })
    it('should return undefined for out-of-range index', () => {
      const arr = [1, 2, 3]
      expect(arr.atTest(3)).toBe(undefined)
      expect(arr.atTest(-4)).toBe(undefined)
    })
    it('should return undefined for empty array', () => {
      expect([].atTest(0)).toBe(undefined)
    })
    it('should return correct result for non-integer index', () => {
      expect([1, 2, 3].atTest(1.5)).toBe(2)
    })
    it('should return correct result for non-numeric index', () => {
      const arr = [1, 2, 3]
      // @ts-expect-error test non-numeric index
      expect(arr.atTest('a')).toBe(1)
      expect(arr.atTest(null)).toBe(1)
      expect(arr.atTest(undefined)).toBe(1)
      // @ts-expect-error test non-numeric index
      expect(arr.atTest({ a: 1 })).toBe(1)
      // @ts-expect-error test non-numeric index
      expect(arr.atTest('3')).toBe(undefined)
      // @ts-expect-error test non-numeric index
      expect(arr.atTest('1')).toBe(2)
    })
    it('should return first item for NaN index', () => {
      expect([1, 2, 3].atTest(NaN)).toBe(1)
    })
  })
}

