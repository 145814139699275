import { requestOfCallCancel } from '@!/utils/useCancelRequest'
import { $http3p } from '../http.js'

// 获取 account list
export const accountManagePage = data => {
  return requestOfCallCancel({
    url: 'accountManage/page',
    method: 'post',
    data
  })
}

// 修改状态
export const changeManaged = data => {
  return requestOfCallCancel({
    url: 'accountManage/change/managed',
    method: 'post',
    data
  })
}

// 修改VendorAccount或者AmazonAccount
export const updateAccountName = data => {
  return requestOfCallCancel({
    url: 'accountManage/updateAccountName',
    method: 'post',
    data
  })
}

/**
 * @description 查询广告sp api不在Commerce的数量
 */
export const GetAdSpApiNotInCommerceCountApi = () => {
  return $http3p({
    url: 'accountManage/getAdSpApiNotInCommerceCount',
    method: 'get'
  })
}

/**
 * @description 查询广告sp api列表
 */
export const GetAdSpApiListApi = ({ sort, sortField } = { sort: '', sortField: '' }) => {
  return $http3p({
    url: `accountManage/getAdSpApiList?sort=${sort}&sortField=${sortField}`,
    method: 'get'
  })
}

/**
 * @description 保存广告sp api saveAdSpApi
 * @param { String } accountId
 * @param { String } accountName
 * @param { String } market
 * @param { Boolean } selected
 * @param { Number } type
 */
export const SaveAdSpApi = data => {
  return $http3p({
    url: 'accountManage/saveAdSpApi',
    method: 'post',
    data
  })
}

/**
 * @description 查询Ad Profile列表
 * @param { Array<string> } market
 * @param { String } sellerId
 * @param { Number } vendorGroupId
 */
export const GetAdProfileListApi = data => {
  return $http3p({
    url: 'accountManage/getAdProfilesList',
    method: 'post',
    data
  })
}

/**
 * @description 更新Ad Profile状态
 * @param { Number } accountType
 * @param { Object } bindInfos
 */
export const UpdateAdProfileBindingStatusApi = (data) => {
  return $http3p({
    url: 'accountManage/updateAdProfilesBindingStatus',
    method: 'post',
    data
  })
}
/**
 * 获取account name filter list
 * @returns {*}
 */
export const getAccountNameList = () => {
  return $http3p({
    url: 'accountManage/getAccountName',
    method: 'get'
  })
}
