import Main from '@pacvue/frame/layout/Main.vue'
import { destroyChart } from '@!/utils/ts-common'

export default {
  path: '/Share',
  component: Main,
  meta: {
    auth: true,
    id: 666
  },
  sort: 1000,
  children: [
    {
      path: '/Share/index',
      component: () => destroyChart(() => import('@!/views/Share/index.vue')),
      name: 'Share'
    }
  ]
  // async beforeEnter(to, from, next) {}
} as import('vue-router').RouteLocationPathRaw
