import request from "@pacvue/frame/request"
let VITE_APP_BASEURL = import.meta.env.VITE_APP_BASEURL
const VITE_APP_META = import.meta.env.VITE_APP_META
//VITE_APP_BASEURL = 'https://localhost:7241/api/'
export * from "./advertising"
export * from "./filter"
export * from "./profile"
export * from "./Campaigns"
export * from "./NegativeTargeting"
export const uploadUrl = VITE_APP_BASEURL + "BulkOperate/V3/UploadBulkFile"
export function getProfile(data) {
  return request({
    url: VITE_APP_META + "filter/profiles",
    method: "post",
    data
  })
}

export function getCampaign(data) {
  return request({
    url: VITE_APP_META + "apply/campaigns",
    method: "post",
    data
  })
}

export function getCampaignTags(data) {
  return request({
    url: VITE_APP_META + "apply/campaignTags",
    method: "post",
    data
  })
}

export function campaignTagsByProfile(data) {
  return request({
    url: VITE_APP_META + "apply/campaignTagsByProfile",
    method: "post",
    data
  })
}

export function getASINTags(data) {
  return request({
    url: VITE_APP_META + "/apply/asinTags",
    method: "post",
    data
  })
}

export function getAdgroupTags(data) {
  return request({
    url: VITE_APP_BASEURL + "Tagging/v3/GetAdGroupTag",
    method: "GET",
    data
  })
}

export function getKeywordTags(data) {
  return request({
    url: VITE_APP_BASEURL + "v3/Keyword/GetKeywordTag",
    method: "get",
    data
  })
}

export function GetCampaignBatchList() {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetCampaignBatchList",
    method: "post"
  })
}

export function PreviewOtherLog(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/PreviewOtherLog",
    method: "post",
    data
  })
}

export function PreviewCampaignAndAdgroupLog(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/PreviewCampaignAndAdgroupLog",
    method: "post",
    data
  })
}

export function GetExcelDataCreateAndEdit(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetExcelDataCreateAndEdit",
    method: "post",
    data
  })
}
export function GetExcelDataCreateAndEditForAmzTemplate(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetExcelDataCreateAndEditForAmzTemplate",
    method: "post",
    data
  })
}
export function BulkGetCampaign(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetCampaign",
    method: "post",
    data
  })
}
export function GetCampaignForAmzTemplate(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetCampaignForAmzTemplate",
    method: "post",
    data
  })
}
export function BulkGetAdGroup(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetAdGroup",
    method: "post",
    data
  })
}
export function GetAdGroupForAmzTemplate(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetAdGroupForAmzTemplate",
    method: "post",
    data
  })
}
export function BulkGetAd(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetAd",
    method: "post",
    data
  })
}
export function GetAdForAmzTemplate(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetAdForAmzTemplate",
    method: "post",
    data
  })
}
export function BulkGetKeyword(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetKeyword",
    method: "post",
    data
  })
}
export function GetKeywordForAmzTemplate(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetKeywordForAmzTemplate",
    method: "post",
    data
  })
}
export function BulkGetNegativeKeyword(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetNegativeKeyword",
    method: "post",
    data
  })
}
export function GetNegativeKeywordForAmzTemplate(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetNegativeKeywordForAmzTemplate",
    method: "post",
    data
  })
}
export function BulkGetTargeting(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetTargetingClause",
    method: "post",
    data
  })
}
export function GetTargetingClauseForAmzTemplate(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetTargetingClauseForAmzTemplate",
    method: "post",
    data
  })
}
export function BulkGetNegativeTargeting(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetNegativeNegativeTargetingClause",
    method: "post",
    data
  })
}
export function GetNegativeNegativeTargetingClauseForAmzTemplate(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetNegativeNegativeTargetingClauseForAmzTemplate",
    method: "post",
    data
  })
}

export function DownloadFile(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/DownloadFile",
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}
export function getPortfolios(data) {
  return request({
    url: VITE_APP_META + "apply/portfolios",
    method: "post",
    data
  })
}
export function getAdgroup(data) {
  return new Promise((resolve, reject) => {
    request({
      url: VITE_APP_META + "apply/adGroups",
      method: "post",
      data
    })
      .then((data) => {
        data = data || []
        var realData = []
        data.forEach((dataItem) => {
          var children = dataItem.children || []
          if (children.length) {
            var leafDatas = children.map((subItem) => {
              return {
                ...subItem,
                profileId: dataItem.pId,
                profileName: dataItem.pName,
                campaignId: dataItem.id,
                campaignName: dataItem.name
              }
            })
            realData.push(...leafDatas)
          } else {
            //realData.push(dataItem)
          }
        })
        resolve(realData)
      })
      .catch((ex) => {
        reject(ex)
      })
  })
}

export function GetCampaignForDownload(data) {
  return request({
    url: VITE_APP_BASEURL + "Campaign/v3/GetCampaignForDownload",
    method: "post",
    data
  })
}

export function ExportAllCampaignAdKeyword(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/ExportAllCampaignAdKeyword",
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

export function ExportAllCampaignAdKeywordTarget(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/ExportAllCampaignAdKeywordTarget",
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

export function GetPortfolioTemplate(data) {
  return request({
    url: VITE_APP_BASEURL + "/BulkOperate/V3/GetPortfolioTemplate",
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

export function GetAsinTagTemplate(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetAsinTagTemplate",
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

export function GetKeywordTagTemplate(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetKeywordTagTemplate",
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

export function GetAdGroupTagTemplate(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetAdGroupTagTemplate",
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

export function PreviewPortfolioTemplateData(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/PreviewPortfolioTemplateData",
    method: "post",
    data
  })
}

export function PreviewAsinTemplateData(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/PreviewAsinTemplateData",
    method: "post",
    data
  })
}

export function PreviewKeywordTemplateData(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/PreviewKeywordTemplateData",
    method: "post",
    data
  })
}

export function PreviewAdGroupTagTemplateData(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/PreviewAdGroupTagTemplateData",
    method: "post",
    data
  })
}
export function PreviewTemplateKeywordMapping(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/PreviewTemplateKeywordMapping",
    method: "post",
    data
  })
}
export function PreviewCampaignTemplateDataForTarget(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/PreviewCampaignTemplateDataForTarget",
    method: "post",
    data
  })
}

//upload download failure
export function ExportPortfolioNotes(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/ExportPortfolioNotes",
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

export function ExportAsinTagNotes(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/ExportAsinTagNotes",
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

export function ExportKeywordTagNotes(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/ExportKeywordTagNotes",
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

export function ExportAdGroupTagNotes(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/ExportAdGroupTagNotes",
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

export function ExportPortfolioNotesLog(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/ExportPortfolioNotesLog",
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

export function ExportAsinTagNotesLog(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/ExportAsinTagNotesLog",
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

export function ExportKeywordTagNotesLog(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/ExportKeywordTagNotesLog",
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

export function InitAMZErrorInfo(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/InitAMZErrorInfo",
    method: "post",
    data
  })
}
export function InitAMZErrorInfoForAmzTemplate(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/InitAMZErrorInfoForAmzTemplate",
    method: "post",
    data
  })
}

//upload save
export function BulkCampaignImportCreateAndUpdate(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/BulkCampaignImportCreateAndUpdate",
    method: "post",
    data
  })
}
export function BulkCampaignImportCreateAndUpdateForAmzTemplate(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/BulkCampaignImportCreateAndUpdateForAmzTemplate",
    method: "post",
    data
  })
}
export function ConfirmUpdateDataForTarget(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/ConfirmUpdateDataForTarget",
    method: "post",
    data
  })
}
export function ConfirmUpdateDataKeywordMapping(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/ConfirmUpdateDataKeywordMapping",
    method: "post",
    data
  })
}
export function CampaignTagBulkSave(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/CampaignTagBulkSave",
    method: "post",
    data
  })
}

export function AsinTagBulkSave(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/AsinTagBulkSave",
    method: "post",
    data
  })
}

export function KeywordTagBulkSave(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/KeywordTagBulkSave",
    method: "post",
    data
  })
}

export function AdGroupTagBulkSave(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/AdGroupTagBulkSave",
    method: "post",
    data
  })
}

export function GetTagBulkLog(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetTagBulkLog",
    method: "post",
    data
  })
}

export function GetMappingBulkBatchList(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetMappingBulkBatchList",
    method: "post",
    data
  })
}

export function GetCampaignBatchListForTarget(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetCampaignBatchListForTarget",
    method: "post",
    data
  })
}

export function GetTagBulkLogDetail(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetTagBulkLogDetail",
    method: "post",
    data
  })
}

export function GetCampaignBatchLogDetailTarget(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetCampaignBatchLogDetailTarget",
    method: "post",
    data
  })
}

export function GetMappingBatchLogDetail(data) {
  return request({
    url: VITE_APP_BASEURL + "BulkOperate/V3/GetMappingBatchLogDetail",
    method: "post",
    data
  })
}
export function SaveToReport(data) {
  return request({
    url: VITE_APP_BASEURL + "SaveToReport/v3/Save ",
    method: "post",
    data,
    fullData: true
  })
}
export function IsCurrentUserCampaignTagPermissionOn(data) {
  return request({
    url: `${VITE_APP_BASEURL}Account/V3/IsCurrentUserCampaignTagPermissionOn`,
    method: "get",
    data: data
  })
}
export function GetCampaignName(data) {
  return request({
    url: `${VITE_APP_BASEURL}BulkOperate/V3/GetCampaignName`,
    method: "post",
    data: data
  })
}

export function GetCampaignPageData(data) {
  return request({
    url: `${VITE_APP_BASEURL}Campaign/v3/GetCampaignPageData`,
    method: "post",
    data: data
  })
}
export function GetAdgroupReportData(data) {
  return request({
    url: `${VITE_APP_BASEURL}Adgroup/v3/GetAdgroupReportData`,
    method: "post",
    data: data
  })
}
export function GetAsinTagPageData(data) {
  return request({
    url: `${VITE_APP_BASEURL}AsinTag/v3/GetAsinTagPageData`,
    method: "post",
    data: data
  })
}
export function GetAsinPageData(data) {
  return request({
    url: `${VITE_APP_BASEURL}Asin/v3/GetAsinPageData`,
    method: "post",
    data: data
  })
}
export function GetTargetingKeywordReport(data) {
  return request({
    url: `${VITE_APP_BASEURL}v3/Keyword/GetTargetingKeywordReport`,
    method: "post",
    data: data
  })
}
export function GetTargetingPATPageData(data) {
  return request({
    url: `${VITE_APP_BASEURL}Targeting/V3/GetTargetingPATPageData`,
    method: "post",
    data: data
  })
}
export function GetNegativeKeywordReport(data) {
  return request({
    url: `${VITE_APP_BASEURL}NegativeTargeting/v3/GetNegativeKeywordReport`,
    method: "post",
    data: data
  })
}
