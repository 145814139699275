
import { OpenAsinDetailTypeEnum, OpenAsinDetailTypeEnumArr } from '@!/const/AsinDetail'
import { setSettings } from '@!/api/Account/settings'
/*

  switchToDrawerTypeInfo -> {
    // 原始保存的路由
    originRoute: object,
    // 打开drawer的参数
    openQuery: object,
    // 打开 drawer 的 tab项
    openTab: string,
    // 打开 drawer的 props
    openProps: object
  }

*/
const getUID = () => localStorage.getItem('uid')
const stateKeys = {
  get openAsinDetailTypeKey() {
    return getUID() + '_openAsinDetailType'
  },
  get switchToDrawerTypeInfoKey() {
    return getUID() + '_switchToDrawerTypeInfo'
  }
}

const state = () => {
  return {
    // 打开 AsinDetail 方式
    openAsinDetailType: localStorage.getItem(stateKeys.openAsinDetailTypeKey) || OpenAsinDetailTypeEnum.Drawer,
    // 从 Tab 方式切换回 Drawer 方式保存的信息
    switchToDrawerTypeInfo: JSON.parse(localStorage.getItem(stateKeys.switchToDrawerTypeInfoKey) || 'null')
  }
}

const actions = {
  // 设置 打开 AsinDetail 方式
  setOpenAsinDetailType(openType = this.openAsinDetailType, pushOrigin = true) {
    const prevOpenType = this.openAsinDetailType
    this.openAsinDetailType = openType
    localStorage.setItem(stateKeys.openAsinDetailTypeKey, openType)
    // pushOrigin 是否同步远程
    if (pushOrigin) {
      return setSettings({
        asinStatusDetail: OpenAsinDetailTypeEnumArr.indexOf(openType)
      }).then(() => {
        return Promise.resolve()
      }).catch(err => {
        console.log('AsinDetail openType push Error: ', err)
        this.openAsinDetailType = prevOpenType
        localStorage.setItem(stateKeys.openAsinDetailTypeKey, prevOpenType)
        return Promise.reject(err)
      })
    }
    return Promise.resolve()
  },
  // 根据元组枚举设置 打开 AsinDetail 方式
  setOpenAsinDetailTypeByArrEnum(arrEnumOpenType = 0, pushOrigin = true) {
    const openType = OpenAsinDetailTypeEnumArr[ arrEnumOpenType ]
    this.setOpenAsinDetailType(openType, pushOrigin)
  },
  // 设置 从 Tab 方式切换回 Drawer 方式保存的信息
  setSwitchToDrawerTypeInfo(info = null) {
    this.switchToDrawerTypeInfo = info
    localStorage.setItem(stateKeys.switchToDrawerTypeInfoKey, JSON.stringify(info))
  },
  clearAsinDetailStore() {
    this.setOpenAsinDetailType(this.openAsinDetailType, false)
    this.setSwitchToDrawerTypeInfo()
    localStorage.removeItem(stateKeys.openAsinDetailTypeKey)
    localStorage.removeItem(stateKeys.switchToDrawerTypeInfoKey)
  }
}

export default {
  state,
  actions,
  persist: {
    enabled: false
  }
}
