import { queryMailboxFolder } from '@!/api/CustomerService/mailbox'
import { queryTypes } from '@!/api/CustomerService/template.js'
import { queryTemplates, queryAddressList } from '@!/api/CustomerService/automatic.js'
import { reactive, watch } from 'vue'
import { isEqual } from 'lodash-es'
const mailboxKey = '3p_customer_service_mailbox_folders'
const mailboxAllKey = '3p_customer_service_mailbox_folders_all'

const customServiceStates = reactive({
  // customerService 绑定的邮箱数据
  mailboxFolders: JSON.parse(sessionStorage[mailboxKey] ?? `{"amazonMessages":0,"emailAddress":[],"recordsOfAutomationRules":0}`),
  mailboxFoldersAll: JSON.parse(sessionStorage[mailboxAllKey] ?? `{"amazonMessages":0,"emailAddress":[],"recordsOfAutomationRules":0}`),
  types: [],
  templates: [],
  addressList: []
})
watch(() => customServiceStates.mailboxFolders, (nv) => {
  sessionStorage.setItem(mailboxKey, JSON.stringify(nv))
}, {
  deep: true
})
watch(() => customServiceStates.mailboxFoldersAll, (nv) => {
  sessionStorage.setItem(mailboxAllKey, JSON.stringify(nv))
}, {
  deep: true
})

const state = () => customServiceStates

const actions = {
  // 设置 customerService 绑定的邮箱
  setMailboxFolder(configProp) {
    customServiceStates.mailboxFolders = configProp
  },
  // 设置 customerService 在 market/store 全选时的绑定邮箱，用于判断是否显示引导页
  setMailboxFolderAll(configProp) {
    customServiceStates.mailboxFoldersAll = configProp
  },
  // 获取 customerService 绑定的邮箱
  async getMailboxFolder(payload, initial = false) {
    try {
      const data = await queryMailboxFolder(payload)
      // 获取全部邮箱时，设置该账号下的全部邮箱数据，用于判断是否显示引导页
      if (initial && isEqual(payload, { sellerIds: [], countryCodes: [] })) {
        actions.setMailboxFolderAll(data)
      } else {
        actions.setMailboxFolder(data)
      }
      return data
    } catch (error) {
      return error
    }
  },
  setTypes: () => {
    queryTypes().then((types) => {
      customServiceStates.types = types.map(({ templateType, templateTypeName }) => {
        return {
          label: templateTypeName,
          value: templateType
        }
      })
    }).catch(e => {
      customServiceStates.types = []
    })
  },
  setTemplates: async() => {
    const data = await queryTemplates()
    customServiceStates.templates = data
    return data
  },
  setAddress: async(payload) => {
    const data = await queryAddressList(payload)
    customServiceStates.addressList = data
    return data
  }
}
export default {
  state,
  actions
}

